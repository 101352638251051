import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $get, $convertMarkdownToHtml } from "../utils/common";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Loading from "../component/Loading";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function Header() {
  const userLocalLang = navigator.language || navigator.userLanguage;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const naverClientID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;
  const location = useLocation();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, login, logout, checkLogin } = useAuth();
  const btnModalRef = useRef();
  const googleLoginRef = useRef();

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: appUrl,
      scope: "profile_nickname, profile_image, account_email",
      //   state: "userme",
      //   serviceTerms: "account_email",
    });
  };

  const requestUserInfo = async (tokenInfo) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
      },
    });

    if (res.status === 401) {
      return;
    }

    const resJson = await res.json();

    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    const res2 = await $post("/api/kakao-login", {
      email: resJson.kakao_account.email,
      nickname: resJson.kakao_account.profile.nickname,
      profile_image_url: resJson.kakao_account.profile.profile_image_url,
      access_token: tokenInfo.access_token,
      access_token_expires_in: tokenInfo.expires_in,
      refresh_token: tokenInfo.refresh_token,
      refresh_token_expires_in: tokenInfo.refresh_token_expires_in,
      lang: userLanguage,
    });

    const userInfo = res2.data[0];

    if (res2.status === 200) {
      login({
        ...userInfo,
      });

      checkLogin();

      // if (userInfo.role_level === 1) {
      //   if (userInfo.active_yn === "N") navigate("/waiting-list");
      // }
    }
  };

  const getToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&redirect_uri=${appUrl}&code=${code}`,
    });

    const resJson = await res.json();

    if (resJson.access_token) {
      window.Kakao.Auth.setAccessToken(resJson.access_token);
      requestUserInfo(resJson);
    }
  };

  const requestKaKaoInfo = async () => {
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get("code");
    if (code) {
      getToken(code);
    }
  };

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const unlinkKakao = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      fetch("https://kapi.kakao.com/v1/user/unlink", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${res.accessToken}`,
        },
      })
        .then((res) => {
          window.Kakao.Auth.setAccessToken(null);
          logout();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not logged in.");
      return;
    }
  };

  const initKaKao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logoutWithKakao(res.accessToken);
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "NAVER") {
        logout();
      }
    }
  };

  const onGoogleSuccess = async (response) => {
    const resData = jwtDecode(response.credential);
    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    const res = await $post("/api/google-login", {
      // tokenId: resData.azp,
      email: resData.email,
      nickname: resData.name,
      profile_image_url: resData.picture,
      access_token_expires_in: resData.exp,
      lang: userLanguage,
    });
    // 서버로 토큰 전송

    const userInfo = res.data[0];
    // setLoading(false);

    if (res.status === 200) {
      login({
        ...userInfo,
      });
    }
  };

  const onGoogleFailure = (response) => {
    console.log("Login Failed:", response);
  };

  const loginWithGoogle = async () => {
    console.log(`${serverUrl}/api/google-login`);
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    // client_id는 위 스크린샷을 보면 발급 받았음을 알 수 있음
    // 단, 스크린샷에 있는 ID가 아닌 당신이 직접 발급 받은 ID를 사용해야 함.
    url += `?client_id=${googleClientId}`;
    // 아까 등록한 redirect_uri
    // 로그인 창에서 계정을 선택하면 구글 서버가 이 redirect_uri로 redirect 시켜줌
    url += `&redirect_uri=${serverUrl}/api/google-login`;
    // 필수 옵션.
    url += "&response_type=code";
    // 구글에 등록된 유저 정보 email, profile을 가져오겠다 명시
    url += "&scope=email profile";
    // 완성된 url로 이동
    // 이 url이 위에서 본 구글 계정을 선택하는 화면임.

    // 브라우저 url을 이동
    document.location.href = url;
  };

  useEffect(() => {
    // scroll 높이가 0 이상이면 헤더에 shadow 추가
    window.addEventListener("scroll", () => {
      const header = document.querySelector("header");
      if (window.scrollY > 50) {
        if (!header.classList.contains("fixed")) {
          header.classList.add("fixed");
        }
      } else {
        if (header.classList.contains("fixed")) {
          header.classList.remove("fixed");
        }
      }
    });
    initKaKao();
    requestKaKaoInfo();
  }, []);

  return (
    <header className="p-3 text-bg-dark">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a
            href="/"
            className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none me-5"
          >
            <img
              src="./assets/images/idot-white.png"
              alt="logo"
              className="logo"
            />
            {/* <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlink:href="#bootstrap"></use></svg> */}
          </a>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li>
              <a
                href="/"
                className={`nav-link px-2 text-white${
                  location.pathname === "/" ? " active" : ""
                }`}
              >
                Home
              </a>
            </li>
            {/* <li>
              <a href="#" className="nav-link px-2 text-white">
                {t("갤러리")}
              </a>
            </li> */}
            <li>
              <a
                href="https://www.wonderslab.ai/pricing"
                target="_blank"
                className="nav-link px-2 text-white"
              >
                {t("구독하기")}
              </a>
            </li>
            <li>
              {userLocalLang.startsWith("ko") && (
                <a
                  href="https://wondersaiguide.oopy.io/33d631a8-f6c5-4267-9628-002342946edf"
                  target="_blank"
                  className="nav-link px-2 text-white"
                >
                  {t("사용자 가이드")}
                </a>
              )}
              {!userLocalLang.startsWith("ko") && (
                <a
                  href="https://wondersaiguideen.oopy.io/cc740a2e-90b8-4338-b6f3-5e5b4b6aa7be"
                  target="_blank"
                  className="nav-link px-2 text-white"
                >
                  {t("사용자 가이드")}
                </a>
              )}
            </li>
            {isLoggedIn && userData && userData.role_level === 0 && (
              <li>
                <a
                  className={`nav-link px-2 text-white${
                    location.pathname === "/userlist" ? " active" : ""
                  }`}
                  onClick={() => navigate("/userlist")}
                >
                  사용자 관리
                </a>
              </li>
            )}

            {isLoggedIn && userData && userData.role_level === 0 && (
              <li>
                <a
                  className={`nav-link px-2 text-white${
                    location.pathname === "/companylist" ? " active" : ""
                  }`}
                  onClick={() => navigate("/companylist")}
                >
                  기업 관리
                </a>
              </li>
            )}
          </ul>

          <div className="text-end">
            {isLoggedIn && (
              <button
                className="btn btn-dark me-2"
                onClick={() => socialLogout()}
              >
                {t("로그아웃")}
              </button>
            )}
            {!isLoggedIn && (
              <button
                type="button"
                className="btn btn-outline-light me-2"
                onClick={() => {
                  if (navigator.userAgent.includes("Safari")) {
                    loginWithGoogle();
                  } else {
                    googleLoginRef.current
                      .querySelector("[role=button]")
                      .click();
                  }
                }}
              >
                {t("로그인/회원가입")}
              </button>
            )}
            {!isLoggedIn && (
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  Swal.fire({
                    iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
                    title: t("지금 무료로 시작하기"),
                    text: t("7일간 무료 이용이 가능합니다."),
                    showCancelButton: true,
                    confirmButtonText: t("네"),
                    cancelButtonText: t("아니오"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      if (navigator.userAgent.includes("Safari")) {
                        loginWithGoogle();
                      } else {
                        googleLoginRef.current
                          .querySelector("[role=button]")
                          .click();
                      }
                    }
                  });
                }}
              >
                {t("지금 무료로 시작하기")}
              </button>
            )}

            <div
              className="google-login"
              style={{ display: "none" }}
              ref={googleLoginRef}
            >
              <GoogleOAuthProvider clientId={googleClientId}>
                <GoogleLogin
                  onSuccess={onGoogleSuccess}
                  onError={onGoogleFailure}
                  size="large"
                  shape="rectangular"
                  logo_alignment="left"
                  type="standard"
                  width="300px"
                  height="52px"
                  useOneTap={false}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        </div>
      </div>
    </header>
    /* <header
      data-bs-theme="dark"
      style={{ position: "sticky", top: "0", zIndex: "9999" }}
    >
      <div className="collapse text-bg-dark" id="navbarHeader">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-md-7 py-4">
              
              <p className="text-body-secondary">
                I(Image)dot은 제품, 모델, 목업, 로고, 일러스트, 사진에 이르는
                전문적인 이미지를 생성할 수 있는 미드저니 프롬프트 AI
                어시스턴트입니다.
              </p>
              {isLoggedIn && userData && userData.role_level === 0 && (
                <nav className="nav">
                  <a
                    className="nav-link active"
                    style={{ cursor: "pointer" }}
                    aria-current="page"
                    onClick={() => navigate("/userlist")}
                  >
                    사용자 관리
                  </a>
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/companylist")}
                  >
                    기업 관리
                  </a>
                  
                </nav>
              )}
            </div>
            <div className="col-sm-4 offset-md-1 py-4">
              <h4>Contact</h4>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="https://www.wonderslab.ai"
                    target="_blank"
                    className="text-white"
                  >
                    WondersLab
                  </a>
                </li>
                <li>
                  <a
                    href="https://wdot.wonderslab.ai"
                    target="_blank"
                    className="text-white"
                  >
                    Wdot
                  </a>
                </li>
                <li>
                  <a
                    href="https://sdot.wonderslab.ai"
                    target="_blank"
                    className="text-white"
                  >
                    Sdot
                  </a>
                </li>
                <li>
                  <a
                    href="mail:wyou@wonderslab.kr"
                    target="_blank"
                    className="text-white"
                  >
                    Email me
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar navbar-dark bg-dark shadow-sm">
        <div className="header">
          <a href="/" className="navbar-brand d-flex align-items-center">
            <img
              src="./assets/images/idot-white.png"
              alt="logo"
              className="logo"
            />
            <strong>Image AI Assistant</strong>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarHeader"
            aria-controls="navbarHeader"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
    </header> */
  );
}

export default Header;
