import { useTranslation } from "react-i18next";

function PageNotFound() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <h1>404 - Page Not Found({t("페이지가 존재하지 않습니다.")})</h1>
    </div>
  );
}

export default PageNotFound;
