import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $get, $convertMarkdownToHtml } from "../utils/common";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Loading from "../component/Loading";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

const imageTypes = [
  {
    title: "Photography",
    type: "",
    content:
      "일반적인 사진 이미지를 생성합니다. 카메라를 사용하여 빛의 강도와 색상을 기록하여 실제 세계의 장면이나 사물을 정확하게 재현할 수 있습니다.",
    url: "/photography/photography.jpg",
    category: "photography",
  },
  {
    title: "Mockup Photography",
    type: "Mockup",
    content:
      "실제 환경에 배치된 제품이나 디자인을 보여주기 위해 사용되는 사진 기법입니다. 이 기법은 특히 그래픽 디자인, 웹 디자인, 앱 개발 및 광고에서 널리 사용됩니다. 목업 사진의 핵심 목적은 제품이나 디자인을 가능한 가장 실제와 가까운 상황에서 보여주는 것입니다.",
    url: "/photography/mockup.jpg",
    category: "photography",
  },
  {
    title: "Product Photography",
    type: "Product",
    content:
      "상품이나 제품을 시각적으로 표현하기 위해 촬영하는 사진 분야입니다. 제품 사진의 주요 목적은 해당 제품의 특징, 디자인, 사용 방법 등을 선명하게 보여주어 소비자의 관심을 끌고 구매를 유도하는 것입니다.",
    url: "/photography/product.jpg",
    category: "photography",
  },
  {
    title: "Model Photography",
    type: "model",
    content:
      "모델 사진은 사람(모델)을 외모, 옷차림, 표정, 포즈 등이 중점적으로 강조됩니다. 모델 사진은 패션, 광고, 포트레이트, 예술 사진 등 다양한 분야에서 중요한 역할을 합니다.",
    url: "/photography/model.jpg",
    category: "photography",
  },
  {
    title: "Food Photography",
    type: "Food",
    content:
      "음식을 주제로 하여 그 맛, 질감, 외관 및 제조 과정을 아름답게 표현하는 사진 장르입니다. 이 장르는 광고, 메뉴, 잡지, 블로그, 소셜 미디어 및 기타 매체에서 활용되며, 음식의 맛과 품질을 시각적으로 전달하는 것을 목적으로 합니다.",
    url: "/photography/food.jpg",
    category: "photography",
  },
  {
    title: "Catalogue Photography",
    type: "Catalogue",
    content:
      "제품이나 서비스를 소비자에게 소개하고 판매를 유도하기 위해 사용되는 사진입니다. 카탈로그 사진은 주로 인쇄 미디어나 온라인 쇼핑몰에서 볼 수 있으며, 제품의 특징, 디자인, 색상 및 크기를 선명하게 보여주는 것이 중요합니다.",
    url: "/photography/catalogue.jpg",
    category: "photography",
  },
  {
    title: "Interior Photography",
    type: "Interior",
    content:
      "인테리어 사진은 건축 내부의 공간과 디자인을 촬영하여 그 특성과 분위기를 포착하는 사진 장르입니다. 주거 공간, 상업 공간, 사무실, 호텔 등 다양한 내부 환경을 대상으로 할 수 있으며, 이러한 사진은 디자인, 광고, 잡지, 온라인 포트폴리오 등 다양한 목적으로 사용됩니다.",
    url: "/photography/interior.jpg",
    category: "interior",
  },
  {
    title: "Exterior Photography",
    type: "Exterior",
    content:
      "건물, 건축물, 주택, 호텔, 상업 시설, 공공 시설 등의 외부를 촬영하는 사진 기법입니다. 이러한 사진은 건축, 부동산, 광고, 디자인, 건축가, 건설업자 등 다양한 분야에서 사용됩니다.",
    url: "/photography/exterior.jpg",
    category: "exterior",
  },
  // {
  //   title: "Landscape Architecture Photography",
  //   type: "Landscape Architecture",
  //   content:
  //     "풍경 건축 사진은 공공 공간, 공원, 정원, 풍경 디자인, 도시 계획, 건축물 등을 포착하는 사진 기법입니다. 이러한 사진은 건축가, 조경 디자이너, 도시 계획가, 정원사, 건축사 등에 의해 사용됩니다.",
  //   url: "/photography/landscape_architecture.jpg",
  //   category: "photography",
  // },
  {
    title: "Architectural Concept Sketches",
    type: "Architectural Concept Sketches",
    content:
      "건축 컨셉 스케치는 건축가가 건축물의 초기 디자인 아이디어를 시각적으로 표현하는 것입니다. 이러한 스케치는 건축물의 외관, 구조, 기능, 재료, 환경, 또는 다른 디자인 요소를 보여주며, 건축가의 창의적인 아이디어와 비전을 전달하는 데 중요한 역할을 합니다.",
    url: "/photography/architectural_concept_sketch.jpg",
    category: "sketch, architecture",
  },
  {
    title: "Illustration Design",
    type: "illustration",
    content:
      "일러스트레이션 이미지는 정보, 메시지 또는 감정을 전달하기 위해 만들어진 그래픽 이미지입니다. 이러한 이미지는 예술적 표현과 함께 실용적인 목적을 달성하기 위해 디자인되며, 일러스트레이션은 다양한 형태와 스타일로 표현될 수 있습니다.",
    url: "/photography/illustration.jpg",
    category: "illustration",
  },
  {
    title: "Flat Illustration",
    type: "flat_illustration",
    content:
      "플랫 일러스트레이션은 2차원적인 평면에 그려진 일러스트레이션 이미지를 말합니다. 이러한 이미지는 주로 간결하고 명확한 디자인을 통해 정보를 전달하거나 메시지를 전달하기 위해 사용됩니다.",
    url: "/photography/flat_illustration.jpg",
    category: "illustration",
  },
  {
    title: "Skeuomorphism Illustration",
    type: "skeuomorphism",
    content:
      "스케우모포즘 일러스트레이션은 실제 세계의 물리적인 속성과 형태를 따라 디자인된 일러스트레이션 이미지를 말합니다. 이러한 이미지는 주로 디지털 제품, 웹사이트, 앱 디자인 등에서 사용되며, 사용자에게 익숙하고 현실적인 느낌을 주기 위해 사용됩니다.",
    url: "/photography/skeuomorphism.jpg",
    category: "illustration",
  },
  // {
  //   title: "Neumorphism Illustration",
  //   type: "neumorphism",
  //   content:
  //     "뉴모피즘 일러스트레이션은 현실적인 느낌을 주기 위해 디자인된 일러스트레이션 이미지를 말합니다. 이러한 이미지는 주로 디지털 제품, 웹사이트, 앱 디자인 등에서 사용되며, 사용자에게 익숙하고 현실적인 느낌을 주기 위해 사용됩니다.",
  //   url: "/photography/illustration.jpg",
  //   category: "illustration",
  // },
  {
    title: "Minimalism Illustration",
    type: "minimalism",
    content:
      "미니멀리즘 일러스트레이션은 간결하고 단순한 디자인을 통해 정보를 전달하거나 메시지를 전달하기 위해 사용되는 일러스트레이션 이미지를 말합니다. 이러한 이미지는 주로 간결하고 명확한 디자인을 통해 정보를 전달하거나 메시지를 전달하기 위해 사용됩니다.",
    url: "/photography/minimalism.jpg",
    category: "illustration",
  },
  {
    title: "Isometric Illustration",
    type: "isometric",
    content:
      "3D 객체를 2D 평면에서 표현하는 그래픽 디자인 기법입니다. 이 기법은 세 개의 축 (보통 X, Y, Z 축) 모두가 동일한 각도 (120도)로 표현되며, 모든 축이 같은 스케일로 그려집니다. 이러한 방식은 오브젝트에 깊이와 입체감을 부여하면서도 2D 평면에 효과적으로 표현할 수 있도록 해줍니다.",
    url: "/photography/isometric.jpg",
    category: "isometric, illustration",
  },
  {
    title: "Logo Design",
    type: "Logo",
    content:
      "로고 디자인은 기업, 브랜드, 제품, 서비스 등을 시각적으로 대표하는 그래픽 마크나 심볼을 만드는 과정입니다. 강력하고 기억에 남는 로고는 브랜드 정체성의 핵심 요소로, 해당 브랜드의 가치, 철학 및 전문성을 한눈에 전달할 수 있어야 합니다.",
    url: "/photography/logo.jpg",
    category: "logo, illustration",
  },
  {
    title: "Pattern Design",
    type: "Pattern",
    content:
      "패턴 디자인은 반복되는 도형, 선, 색상, 또는 텍스처를 사용하여 시각적으로 매력적인 디자인을 만드는 과정입니다. 직물, 벽지, 포장재, 웹사이트 배경, 그래픽 디자인 프로젝트 등 다양한 맥락에서 사용됩니다.",
    url: "/photography/tile.jpg",
    category: "pattern, illustration",
  },
  {
    title: "Paper Cut Craft",
    type: "papercut",
    content:
      "Paper Cut Craft는 종이를 절단, 접기, 조각하는 등의 방법으로 아름다운 예술 작품을 만드는 공예 기법입니다. 섬세함과 창의력을 요구하며, 종이 한 장으로 꽃, 동물, 인물, 풍경 등 다양한 이미지를 표현할 수 있습니다. 종이 접기, 절단, 조각 등의 기술을 사용하여 다양한 형태의 작품을 만들어낼 수 있습니다.",
    url: "/photography/papercut.jpg",
    category: "papercut, craft",
  },
  {
    title: "Pencil Sketch",
    type: "pencil",
    content:
      "연필 스케치는 연필을 사용하여 종이나 캔버스에 그림을 그리는 기법입니다. 이 기법은 주로 풍경, 인물, 동물, 꽃, 식물 등 다양한 주제를 다루며, 선의 질감과 그림자를 강조하는 것이 특징입니다.",
    url: "/photography/pencil_sketch.jpg",
    category: "sketch, painting, drawing, illustration",
  },
  {
    title: "Continuous Line Sketch",
    type: "continuous_line",
    content:
      "연속선 드로잉(Continuous Line Drawing)은 한 번에 한 줄로 그림을 그리는 기법입니다. 이 기법은 주로 물체나 인물의 윤곽을 강조하고, 선의 흐름과 움직임을 강조하는 데 사용됩니다. 연속선 드로잉은 빠르고 감각적인 그림을 만들어내며, 물체의 형태와 구조를 강조하는 데 효과적입니다.",
    url: "/photography/continuous_line.jpg",
    category: "sketch, painting, drawing, illustration",
  },
  {
    title: "Dripping Painting",
    type: "dripping_painting",
    content:
      "Dripping Painting은 페인트를 캔버스에 직접 떨어뜨리거나 흘려보내어 만드는 추상 미술 기법입니다. 이 기법은 특히 미술가의 감정과 감정을 표현하는 데 사용되며, 특히 미술가의 감정과 감정을 표현하는 데 사용됩니다.",
    url: "/photography/dripping_painting.jpg",
    category: "dripping_painting, painting, drawing, illustration",
  },
  {
    title: "Cubism Drawing",
    type: "cubism",
    content:
      "큐비즘 드로잉(Cubism Drawing)은 20세기 초에 발생한 큐비즘(Cubism) 미술 운동에서 파생된 그림 기법입니다. 큐비즘은 전통적인 관점에서 벗어나 객체를 분석하고, 다양한 각도에서 관찰된 형태를 하나의 평면에 재구성하는 것을 목표로 합니다.",
    url: "/photography/cubism1.jpg",
    category: "cubism, drawing, illustration",
  },
  {
    title: "Pop Art",
    type: "popart",
    content:
      "Pop Art는 1950년대 후반부터 1960년대 초반에 발전한 미술 운동으로, 대중문화와 소비문화를 주제로 한 미술 작품을 만드는 것을 중심으로 합니다. 이러한 작품은 주로 광고, 제품 포장, 만화, 팝 아이콘, 유명인 등을 대상으로 하며, 선명한 색상과 대조적인 패턴, 그리고 반복적인 이미지를 특징으로 합니다.",
    url: "/photography/popart.jpg",
    category: "popart, drawing, illustration",
  },
  {
    title: "Mosaic illustration",
    type: "mosaic",
    content:
      "Mosaic은 작은 조각들을 조합하여 큰 이미지를 만드는 기법입니다. 이 기법은 주로 타일, 유리, 돌, 종이, 나무, 또는 다른 재료를 사용하여 다양한 패턴과 이미지를 만들어냅니다. Mosaic은 주로 벽, 바닥, 천장, 또는 다른 표면에 장식적인 요소로 사용되며, 종종 예술 작품으로도 사용됩니다.",
    url: "/photography/mosaic.jpg",
    category: "mosaic, craft, illustration",
  },
  {
    title: "Watercolor Drawing",
    type: "watercolor",
    content:
      "Watercolor은 물과 색소를 사용하여 그림을 그리는 기법입니다. 이 기법은 주로 종이나 캔버스에 사용되며, 물과 색소를 사용하여 다양한 색상과 텍스처를 만들어냅니다. Watercolor은 주로 풍경, 인물, 동물, 꽃, 식물 등 다양한 주제를 다루며, 그림의 물성과 투명도를 강조하는 것이 특징입니다.",
    url: "/photography/watercolor.jpg",
    category: "watercolor, painting, drawing, illustration",
  },
  {
    title: "Botanical illustration",
    type: "botanical",
    content:
      "식물 일러스트레이션(Botanical Illustration)은 식물의 모습을 정밀하게 묘사하는 미술 분야입니다. 이러한 일러스트레이션은 과학적 정확성을 바탕으로 식물의 형태, 색상, 세부적인 특징들을 세심하게 표현합니다. 식물 일러스트레이션의 주요 목적은 특정 식물 종의 식별과 이해를 돕기 위해 그 식물의 특징을 정확하고 상세하게 전달하는 것입니다.",
    url: "/photography/botanical.jpg",
    category: "botanical, illustration",
  },
  {
    title: "Embroidery Design",
    type: "embroidery",
    content:
      "자수 디자인(Embroidery Design)은 천이나 다른 재료 위에 바늘과 실을 사용하여 패턴, 장식, 글자, 이미지를 수놓는 공예의 한 형태입니다. 자수는 수천 년 동안 전 세계의 다양한 문화에서 발전해 왔으며, 전통적인 수공예에서 현대적인 디지털 자수에 이르기까지 다양한 스타일과 기법이 있습니다.",
    url: "/photography/embroidery.jpg",
    category: "embroidery, craft",
  },
  {
    title: "Street Style Photography",
    type: "Street Style",
    content:
      "거리에서 스타일리시하거나 독특한 패션을 착용한 사람들을 포착하는 사진 스타일입니다. 대체로 유행이나 개인의 스타일을 강조하기 위해 촬영되며, 패션 위주의 블로그, 잡지, 소셜 미디어 플랫폼 등에서 자주 볼 수 있습니다.",
    url: "/photography/street_style.jpg",
    category: "photography",
  },
  {
    title: "Glamour Shot Photography",
    type: "Glamour Shot",
    content:
      "사람들을 특히나 아름답게, 매력적으로 보이게 찍는 사진 스타일입니다. 이 유형의 사진술은 대상의 미모와 매력을 강조하며, 이를 위해 화장, 의상, 조명, 백그라운드, 포즈 등 다양한 요소를 조합하여 사용합니다.",
    url: "/photography/glamor_shot.jpg",
    category: "photography",
  },
  {
    title: "Candid Photography",
    type: "Candid",
    content:
      "대상이 카메라를 의식하지 않거나 인지하지 않는 상태에서 자연스럽게 찍힌 사진을 말합니다. 이러한 사진은 대상의 진실된 순간과 감정을 포착하기 때문에 종종 강력하고 생생한 이미지를 만들어냅니다.",
    url: "/photography/candid.jpg",
    category: "photography",
  },
  {
    title: "Underwater Photography",
    type: "Underwater",
    content:
      "물 아래에서 찍는 사진술로, 해양 생물, 다이버, 잠수함, 선박의 잔해, 바다 및 강의 경치 등 다양한 주제를 포착하는 데 사용됩니다. 물 아래의 환경은 특별한 도전과 기술이 필요하며, 사진작가는 물과 빛의 다른 특성에 대한 깊은 이해가 필요합니다.",
    url: "/photography/underwater.jpg",
    category: "photography",
  },
  {
    title: "Night and Low Light Photography",
    type: "Night and Low Light",
    content:
      "야간 및 저조명 포토그래피는 빛의 양이 제한적인 환경에서 촬영됩니다. 이러한 조건에서는 카메라 설정, 기술 및 장비에 대한 깊은 이해가 필요합니다.",
    url: "/photography/night_low.jpg",
    category: "photography",
  },

  {
    title: "Macro Photography",
    type: "Macro",
    content:
      "매우 가까운 거리에서 작은 대상을 크게 찍는 사진술입니다. 이 사진술은 대상의 세밀한 부분과 디테일을 대대적으로 보여줄 수 있습니다. 일반적으로 보이지 않거나 눈에 띄지 않는 미세한 세계를 탐험하고자 할 때 주로 사용됩니다.",
    url: "/photography/macro.jpg",
    category: "photography",
  },
  {
    title: "Wildlife Photography",
    type: "Wildlife",
    content:
      "야생 동물의 자연스러운 환경에서의 행동, 모습, 생태계를 포착하는 사진 분야입니다. 이 분야는 동물들의 순간적인 행동이나 아름다운 모습을 포착하는 것이 중요하며, 동시에 동물과 자연에 대한 깊은 이해와 존중이 필요합니다.",
    url: "/photography/wildlife.jpg",
    category: "photography",
  },
  {
    title: "National geographic Photography",
    type: "National geographic",
    content:
      "세계에서 가장 잘 알려진 및 공신력 있는 출판물 중 하나로, 1888년에 설립된 이래로 세계 각지의 문화, 지리, 과학, 역사 및 자연 환경에 관한 깊이 있는 기사와 아름다운 사진을 통해 독자들에게 지구의 다양성과 복잡성을 소개해왔습니다.",
    url: "/photography/national_geographic.jpg",
    category: "photography",
  },
  {
    title: "Landscape Photography",
    type: "Landscape",
    content:
      "자연의 아름다움을 포착하는 사진 예술의 한 분야로, 대체로 자연환경에서 촬영되지만 도시나 산업 지역의 풍경을 포착하는 것도 포함될 수 있습니다.",
    url: "/photography/landscape.jpg",
    category: "photography",
  },
  {
    title: "Nature Photography",
    type: "Nature",
    content:
      "자연 환경 내에서의 풍경, 식물, 동물, 지형 및 다른 자연적 요소들을 포착하는 사진 분야입니다. 자연 사진은 주로 아름다움, 극적인 순간, 또는 자연의 미묘한 변화를 기록하고자 하는 목적으로 찍힙니다.",
    url: "/photography/nature.jpg",
    category: "photography",
  },
  {
    title: "Architecture Photography",
    type: "Architecture",
    content:
      "건물, 구조물 및 다른 인공적인 구조물의 아름다움, 디자인, 기술적 특성을 포착하는 사진의 한 분야입니다. 건축 사진은 건축, 디자인, 히스토리 및 문화에 대한 관심을 가진 사람들에게 인기가 있습니다.",
    url: "/photography/architecture.jpg",
    category: "photography",
  },
  {
    title: "Street and Travel Photography",
    type: "Street and Travel",
    content:
      "일상의 순간과 상황을 포착하는 사진 스타일로, 대개는 공공 장소에서 촬영됩니다. 이러한 사진은 사람들, 거리의 활동, 일상의 장면 등을 포착하며, 보통 계획되지 않은 순간적인 상황을 잡아냅니다.",
    url: "/photography/street_travel.jpg",
    category: "photography",
  },
  {
    title: "Lifestyle Photography",
    type: "Lifestyle",
    content:
      "일상 생활의 순간과 경험을 찍는 사진 스타일입니다. 라이프스타일 사진은 전통적인 포트레이트나 캔디드 사진과는 달리, 주로 사람들이 자연스럽게, 그리고 종종 연출된 환경에서의 활동이나 행동을 포착합니다. 이는 일상의 아름다움과 감정, 그리고 사람들의 관계와 생활 방식을 기록하고자 하는 의도를 반영합니다.",
    url: "/photography/lifestyle.jpg",
    category: "photography",
  },
  {
    title: "Catchlight Photography",
    type: "Catchlight",
    content:
      "주로 인물의 눈에 반사되어 보이는 밝은 빛 또는 반사빛을 의미합니다. 이 반사빛은 눈동자에 작은 빛나는 점이나 모양으로 나타나며, 포트레이트나 인물 사진에서 주로 볼 수 있습니다. 캐치라이트는 사진의 주제에 생명감을 주고, 관심을 끌며, 눈의 깊이와 구조를 강조하는 데 중요한 역할을 합니다.",
    url: "/photography/catchlight.jpg",
    category: "photography",
  },
  {
    title: "Photojournalism Photography",
    type: "Photojournalism",
    content:
      "뉴스나 실제 사건을 시각적으로 문서화하고 전달하는 사진 분야입니다. 포토저널리즘은 단순히 사진을 찍는 것을 넘어서 해당 사건의 중요성과 의미를 전달하는 데 중점을 둡니다.",
    url: "/photography/photojournalism.jpg",
    category: "photography",
  },
  {
    title: "Documentary Photography",
    type: "Documentary",
    content:
      "특정 사람, 사건, 이슈, 또는 사회와 문화적인 현상을 사실적으로 기록하고 문서화하기 위한 사진 장르입니다. 이는 주로 정보 제공 및 교육적 목적, 또는 사회적이나 정치적 변화를 촉진하는 데에 사용됩니다. 다큐멘터리 사진은 주로 기자, 연구자, 또는 활동가에 의해 찍히며, 이들은 특정 이슈나 주제에 대한 깊은 이해를 바탕으로 작업합니다.",
    url: "/photography/documentary.jpg",
    category: "photography",
  },

  {
    title: "Tilt Shift Photography",
    type: "Tilt Shift",
    content:
      "특별한 렌즈를 사용하거나 포스트 프로세싱 기술을 활용하여 특정 부분을 초점에 맞추고 주변 부분을 흐릿하게 만들어 사진의 주제를 마치 미니어처처럼 보이게 하는 기술입니다. 이러한 효과는 주로 크고 넓은 장면에서 효과적으로 작동하며, 실제 장면을 작은 스케일 모델처럼 보이게 만듭니다.",
    url: "/photography/tilt_shift.jpg",
    category: "photography",
  },
  {
    title: "Black and White Photography",
    type: "Black and White",
    content:
      "모든 색상 정보를 제거하고 오로지 명도(빛과 어둠)만으로 이미지를 표현하는 사진 장르입니다.",
    url: "/photography/black_white.jpg",
    category: "photography",
  },
  {
    title: "Film Grain Photography",
    type: "Film Grain",
    content:
      "전통적인 필름 사진에 나타나는 미세한 입자 또는 결점을 나타내는 용어입니다. 이는 디지털 이미지에서의 '노이즈'와 유사한 개념이지만, 그 원인과 특성이 다릅니다. 필름 그레인은 사진을 특별하고 독특하게 만드는 중요한 요소로, 많은 사람들이 그레인이 있는 사진의 무드와 텍스처를 선호합니다.",
    url: "/photography/film_grain.jpg",
    category: "photography",
  },
  {
    title: "Time-Lapse Photography",
    type: "Time-Lapse",
    content:
      "일정한 시간 간격으로 찍은 연속적인 사진들을 연속적으로 재생하여 동영상을 만드는 기술입니다. 타임랩스를 통해 긴 시간 동안의 변화를 몇 초나 몇 분 동안의 짧은 동영상으로 볼 수 있습니다. 이로 인해 꽃이 피는 과정, 건물이 지어지는 과정, 별의 움직임 등의 빠르게 변화하는 장면을 관람자가 짧은 시간 안에 경험할 수 있게 됩니다.",
    url: "/photography/time_lapse.jpg",
    category: "photography",
  },
  {
    title: "Motion Photography",
    type: "Motion",
    content:
      "주로 움직임을 포착하거나 표현하는 사진에 관련된 것을 의미합니다. 움직임을 포착하는 여러 기술이 있으며, 그 결과로 다양한 효과와 무드를 만들어낼 수 있습니다.",
    url: "/photography/motion.jpg",
    category: "photography",
  },
  {
    title: "High-Speed Photography",
    type: "High-Speed",
    content: "빠르게 움직이는 객체나 현상을 순간적으로 포착하는 사진술입니다.",
    url: "/photography/high_speed.jpg",
    category: "photography",
  },
  {
    title: "Pinhole Photography",
    type: "Pinhole",
    content:
      "아주 작은 구멍(핀홀)을 통해 빛이 들어와 상자 안쪽의 필름이나 디지털 센서에 이미지를 프로젝션합니다.",
    url: "/photography/pinhole.jpg",
    category: "photography",
  },
  {
    title: "Stop Motion Photography",
    type: "Stop Motion",
    content:
      "정지된 이미지들의 연속을 사용하여 움직이는 영상을 만드는 방식입니다. 각각의 정지된 이미지는 순차적으로 찍히며, 미세하게 움직이는 객체나 캐릭터를 포착합니다.",
    url: "/photography/stop_motion.jpg",
    category: "photography",
  },
  {
    title: "Sports and Action Photography",
    type: "Sports and Action",
    content:
      "빠르게 움직이는 대상을 포착하는 사진의 한 분야입니다. 이러한 사진은 주로 스포츠 행사, 동물의 움직임, 또는 다른 빠른 행동을 포착하기 위해 촬영됩니다.",
    url: "/photography/sports_action.jpg",
    category: "photography",
  },
  {
    title: "Action Scene Photography",
    type: "Action Scene",
    content:
      "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
    url: "/photography/action_scene.jpg",
    category: "photography",
  },
  {
    title: "Portrait Photography",
    type: "Portrait",
    content:
      "개인 또는 그룹의 모습을 중심으로 촬영하는 사진 유형입니다. 포트레이트 사진의 주 목적은 대상의 표정, 성격, 그리고 감정을 포착하는 것입니다.",
    url: "/photography/portrait.jpg",
    category: "portrait",
  },
  {
    title: "Abstract Effect",
    type: "portrait",
    content:
      "추상(abstract) 효과는 사진의 주제나 대상을 현실적인 형태나 색상에서 벗어나게 만드는 기법입니다. 이러한 효과는 주로 색상, 형태, 패턴, 또는 빛과 그림자를 활용하여 창의적이고 독특한 이미지를 만들어냅니다.",
    url: "/portrait/portrait_abstract.jpg",
    category: "portrait",
    effect: "abstract",
  },
  {
    title: "Bokeh Effect",
    type: "portrait",
    content:
      "보케(bokeh) 효과는 사진의 배경이나 전경을 흐릿하게 만들어 주제를 강조하는 기법입니다. 이 효과는 주로 렌즈의 조리개 크기와 모양, 초점 거리, 렌즈 유형 등에 따라 변화하며, 주로 포트레이트나 매크로 사진에서 사용됩니다.",
    url: "/portrait/portrait_bokeh.jpg",
    category: "portrait",
    effect: "bokeh",
  },
  {
    title: "Contre Jour Effect",
    type: "portrait",
    content:
      "콘트르 쥬르(contre jour) 효과는 대상이나 주제를 빛의 반대편에서 찍어 그림자를 만들어내는 기법입니다. 이러한 효과는 주로 대상의 윤곽을 강조하거나, 감정을 강조하는 데 사용됩니다.",
    url: "/portrait/portrait_contre_jour.jpg",
    category: "portrait",
    effect: "contre jour",
  },
  {
    title: "Edge Darkening Effect",
    type: "portrait",
    content:
      "엣지 다크닝(edge darkening) 효과는 사진의 가장자리를 어둡게 만들어 중심에 있는 대상을 강조하는 기법입니다. 이 효과는 주로 포트레이트나 풍경 사진에서 사용되며, 주로 대상의 주목을 끌기 위해 사용됩니다.",
    url: "/portrait/portrait_edge_darkening.jpg",
    category: "portrait",
    effect: "edge darkening",
  },
  {
    title: "High Key Effect",
    type: "portrait",
    content:
      "하이 키(high key) 효과는 밝고 화사한 이미지를 만들어내는 기법입니다. 이 효과는 주로 밝은 배경과 대상을 사용하여 고급스러운 이미지를 만들어내며, 주로 포트레이트나 패션 사진에서 사용됩니다.",
    url: "/portrait/portrait_high_key.jpg",
    category: "portrait",
    effect: "high key",
  },
  {
    title: "Infrared Effect",
    type: "portrait",
    content:
      "적외선(infrared) 효과는 적외선 필터를 사용하여 사진을 찍어 대상의 색상과 텍스처를 변형하는 기법입니다. 이 효과는 주로 독특하고 신비로운 이미지를 만들어내며, 주로 풍경이나 포트레이트 사진에서 사용됩니다.",
    url: "/portrait/portrait_infrared.jpg",
    category: "portrait",
    effect: "infrared",
  },
  {
    title: "Janus Effect",
    type: "portrait",
    content:
      "자누스(Janus) 효과는 대상이 카메라를 바라보는 동시에 다른 방향을 바라보는 것처럼 보이게 만드는 기법입니다. 이 효과는 주로 대상의 이중적인 성격이나 감정을 강조하는 데 사용됩니다.",
    url: "/portrait/portrait_janus_effect.jpg",
    category: "portrait",
    effect: "janus effect",
  },
  {
    title: "Kirlian Effect",
    type: "portrait",
    content:
      "키를리안(Kirlian) 효과는 대상의 에너지 필드를 시각적으로 나타내는 기법입니다. 이 효과는 주로 대상의 에너지, 정신적 상태, 또는 감정을 나타내는 데 사용됩니다.",
    url: "/portrait/portrait_kirlian_effect.jpg",
    category: "portrait",
    effect: "kirlian effect",
  },
  {
    title: "Long Exposure Effect",
    type: "portrait",
    content:
      "롱 익스포저(long exposure) 효과는 카메라의 셔터를 오랫동안 열어 빛을 캡처하는 기법입니다. 이 효과는 주로 움직이는 대상을 흐리게 만들거나, 빛의 궤적을 만들어내는 데 사용됩니다.",
    url: "/portrait/portrait_long_exposure.jpg",
    category: "portrait",
    effect: "long exposure",
  },
  {
    title: "Multisonic Effect",
    type: "portrait",
    content:
      "멀티소닉(multisonic) 효과는 대상의 다양한 감정이나 성격을 나타내는 이미지를 만들어내는 기법입니다. 이 효과는 주로 대상의 다양한 면을 보여주거나, 대상의 다양한 감정을 나타내는 데 사용됩니다.",
    url: "/portrait/portrait_multisonic_effect.jpg",
    category: "portrait",
    effect: "multisonic effect",
  },
  {
    title: "Quasar Burst Effect",
    type: "portrait",
    content:
      "퀘이사르 버스트(quasar burst) 효과는 대상 주위에 빛이 폭발하는 것처럼 보이게 만드는 기법입니다. 이 효과는 주로 대상의 에너지, 열정, 또는 활기를 나타내는 데 사용됩니다.",
    url: "/portrait/portrait_quasar_burst.jpg",
    category: "portrait",
    effect: "quasar burst",
  },
  {
    title: "Rolling Shutter Effect",
    type: "portrait",
    content:
      "롤링 셔터(rolling shutter) 효과는 카메라의 셔터가 상하로 움직이는 동안 찍히는 이미지를 왜곡시키는 기법입니다. 이 효과는 주로 움직이는 대상이나 빠르게 움직이는 대상을 포착하는 데 사용됩니다.",
    url: "/portrait/portrait_rolling_shutter_effect.jpg",
    category: "portrait",
    effect: "rolling shutter effect",
  },
  {
    title: "Selective Coloring Effect",
    type: "portrait",
    content:
      "셀렉티브 컬러링(selective coloring) 효과는 특정 색상을 강조하고 나머지를 흑백으로 만드는 기법입니다. 이 효과는 주로 대상의 특정 부분이나 주제를 강조하는 데 사용됩니다.",
    url: "/portrait/portrait_selective_coloring.jpg",
    category: "portrait",
    effect: "selective coloring",
  },
  {
    title: "Texture Overlay Effect",
    type: "portrait",
    content:
      "텍스처 오버레이(texture overlay) 효과는 사진 위에 다른 이미지나 텍스처를 덧씌워 새로운 이미지를 만들어내는 기법입니다. 이 효과는 주로 사진에 깊이와 텍스처를 더하거나, 새로운 느낌을 주는 데 사용됩니다.",
    url: "/portrait/portrait_texture_overlay.jpg",
    category: "portrait",
    effect: "texture overlay",
  },
  {
    title: "Unicolor Effect",
    type: "portrait",
    content:
      "유니컬러(unicolor) 효과는 사진 전체를 단일 색상으로 만드는 기법입니다. 이 효과는 주로 사진에 독특한 느낌을 주거나, 특정 감정이나 분위기를 나타내는 데 사용됩니다.",
    url: "/portrait/portrait_unicolor.jpg",
    category: "portrait",
    effect: "unicolor",
  },
  {
    title: "Voronoi Map Effect",
    type: "portrait",
    content:
      "보로노이 맵(voronoi map) 효과는 대상 주위에 다각형 모양의 셀을 만들어내는 기법입니다. 이 효과는 주로 대상을 강조하거나, 대상의 윤곽을 강조하는 데 사용됩니다.",
    url: "/portrait/portrait_voronoi_map.jpg",
    category: "portrait",
    effect: "voronoi map",
  },
  {
    title: "Xylography Effect",
    type: "portrait",
    content:
      "목판화(xylography) 효과는 사진을 목판화로 만드는 기법입니다. 이 효과는 주로 사진에 고전적이고 독특한 느낌을 주는 데 사용됩니다.",
    url: "/portrait/portrait_xylography_effect.jpg",
    category: "portrait",
    effect: "xylography effect",
  },
  {
    title: "Zoom Burst Effect",
    type: "portrait",
    content:
      "줌 버스트(zoom burst) 효과는 카메라의 줌을 조절하면서 촬영하여 대상을 확대 또는 축소하는 기법입니다. 이 효과는 주로 움직임이나 에너지를 강조하는 데 사용됩니다.",
    url: "/portrait/portrait_zoom_burst_effect.jpg",
    category: "portrait",
    effect: "zoom burst effect",
  },
  // {
  //   title: "Block Print Style",
  //   type: "Block print style",
  //   content:
  //     "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
  //   url: "/photography/action_scene.jpg",
  // },
  // {
  //   title: "Folk Art Style",
  //   type: "Folk art style",
  //   content:
  //     "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
  //   url: "/photography/action_scene.jpg",
  // },
  // {
  //   title: "Risograph Style",
  //   type: "Risograph style",
  //   content:
  //     "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
  //   url: "/photography/action_scene.jpg",
  // },
  {
    title: "Oil Painting",
    type: "Oil painting",
    content:
      "유성 페인트를 사용하여 캔버스, 목재 패널, 또는 다른 표면에 그림을 그리는 예술 형태입니다. 유화는 그 유연성, 풍부한 색감, 다양한 질감 표현 능력 때문에 중세 말부터 예술가들에게 널리 사용되어 왔습니다. 이 기법은 섬세한 세부 묘사와 깊이 있는 색상의 레이어링을 가능하게 하며, 작품에 입체감과 생동감을 부여합니다.",
    url: "/photography/oil_painting.jpg",
    category: "drawing, painting",
  },
  // {
  //   title: "Charcoal Sketch Style",
  //   type: "Charcoal sketch style",
  //   content:
  //     "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
  //   url: "/photography/action_scene.jpg",
  // },
  // {
  //   title: "Blacklight Painting",
  //   type: "Blacklight painting",
  //   content:
  //     "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
  //   url: "/photography/action_scene.jpg",
  // },
  // {
  //   title: "Pixel Art Style",
  //   type: "Pixel art style",
  //   content:
  //     "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
  //   url: "/photography/action_scene.jpg",
  // },
  // {
  //   title: "Pencil Sketch Style",
  //   type: "Pencil sketch style",
  //   content:
  //     "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
  //   url: "/photography/action_scene.jpg",
  // },
  // {
  //   title: "Continuous Line Sketch Style",
  //   type: "Continuous line sketch style",
  //   content:
  //     "움직이는 주체나 객체, 또는 긴장감 있는 순간을 포착하는 사진 기법입니다. 스포츠, 모험, 또는 다른 움직임 중심의 활동에서 자주 볼 수 있는 사진입니다. 액션 씬 사진은 관람자에게 순간적인 움직임과 에너지, 그리고 그 순간의 감정을 전달합니다.",
  //   url: "/photography/action_scene.jpg",
  // },
  {
    title: "Modern Style",
    type: "in Modern style",
    content:
      "모던(현대) 인테리어 디자인은 20세기 초반부터 현재까지의 디자인 스타일을 포괄하는 용어로, 간결하고 깔끔한 선, 기하학적인 형태, 현대적인 소재와 기술을 사용하여 현대적이고 실용적인 분위기를 조성합니다.",
    url: "/photography/modern.jpg",
    category: "interior",
  },
  {
    title: "Contemporary Style",
    type: "in Contemporary style",
    content:
      "컨템퍼러리(현대) 인테리어 디자인은 현재와 가까운 시대의 스타일을 반영하며, 지속적으로 변화하는 디자인 트렌드를 따릅니다. 이는 모던 디자인의 원칙에 기반을 두되 더 유연하고 다양한 요소를 포용합니다.",
    url: "/photography/contemporary.jpg",
    category: "interior",
  },
  {
    title: "Transitional Style",
    type: "in Transitional style",
    content:
      "트랜지셔널(중간형) 인테리어 디자인은 전통적인 디자인의 우아함과 현대 디자인의 깔끔한 선을 결합한 스타일입니다. 이 디자인은 두 세계의 장점을 융합하여 편안하면서도 세련된 분위기를 조성합니다.",
    url: "/photography/transitional.jpg",
    category: "interior",
  },
  {
    title: "Scandinavian Style",
    type: "in Scandinavian style",
    content:
      "스칸디나비아(Scandinavian) 인테리어 디자인은 북유럽의 국가들인 덴마크, 스웨덴, 노르웨이의 전통적인 스타일을 반영하며, 간결하고 밝은 색상, 자연 소재, 기능성을 중시합니다. 이 스타일은 깔끔하고 아늑한 분위기를 조성합니다.",
    url: "/photography/scandinavian.jpg",
    category: "interior",
  },
  {
    title: "Mediterranean Style",
    type: "in Mediterranean style",
    content:
      "지중해(Mediterranean) 인테리어 디자인은 지중해 연안 지역인 이탈리아, 그리스, 스페인에서 영향을 받은 스타일입니다. 이 디자인은 따뜻한 색상, 자연 소재, 실내외 공간의 흐름을 중시합니다.",
    url: "/photography/mediterranean.jpg",
    category: "interior",
  },
  {
    title: "Shabby Chic Style",
    type: "in Shabby Chic style",
    content:
      "Shabby Chic은 낡고 허름한 매력을 고의적으로 연출하여 아늑하고 로맨틱한 분위기를 조성하는 인테리어 디자인 스타일입니다. 빈티지한 느낌과 여성스러움, 부드러운 색감이 조화를 이루는 것이 특징입니다.",
    url: "/photography/shabby_chic.jpg",
    category: "interior",
  },
  {
    title: "Coastal Style",
    type: "in Coastal style",
    content:
      "코스탈(Coastal) 인테리어 디자인은 해변과 바다에서 영감을 받은 스타일로, 밝고 통풍이 잘되며 편안한 분위기를 조성합니다. 이 스타일은 자연스러운 소재와 색상을 사용하여 해안가의 여유로움과 평화로움을 실내에 반영합니다.",
    url: "/photography/coastal.jpg",
    category: "interior",
  },
  {
    title: "Bauhaus Style",
    type: "in Bauhaus style",
    content:
      "바우하우스(Bauhaus)는 20세기 초반 독일에서 설립된 디자인 학교로, 기능성과 현대성을 중시하는 디자인 스타일을 대표합니다. 이 스타일은 간결하고 기하학적인 선과 형태를 사용하여 현대적이면서도 실용적인 분위기를 조성합니다.",
    url: "/photography/bauhaus.jpg",
    category: "interior",
  },
  {
    title: "Bohemian Style",
    type: "in Bohemian style",
    content:
      "보헤미안(Bohemian) 인테리어 디자인은 자유로움과 창의성을 중시하는 스타일로, 다양한 색상과 패턴, 소재를 조합하여 독특하고 아늑한 분위기를 조성합니다.",
    url: "/photography/bohemian.jpg",
    category: "interior",
  },
  {
    title: "Art Deco Style",
    type: "in Art Deco style",
    content:
      "아르데코(Art Deco)는 1920년대에 유럽에서 시작된 인테리어 디자인 스타일로, 기하학적인 패턴과 선, 화려한 색상, 고급스러운 소재를 사용하여 화려하고 세련된 분위기를 조성합니다.",
    url: "/photography/art_deco.jpg",
    category: "interior",
  },
  {
    title: "Rustic Style",
    type: "in Rustic style",
    content:
      "러스틱(Rustic) 인테리어 디자인은 자연 소재와 따뜻한 색상, 노출된 나무와 돌, 또는 철재 등의 소재를 사용하여 자연스러우면서도 따뜻한 분위기를 조성합니다.",
    url: "/photography/rustic.jpg",
    category: "interior",
  },
  {
    title: "Minimalist Style",
    type: "in Minimalist style",
    content:
      "미니멀리스트(Minimalist) 인테리어 디자인은 깨끗하고 간결한 라인과 형태를 중시하는 스타일로, 불필요한 장식을 배제하여 깔끔하고 조화로운 분위기를 조성합니다.",
    url: "/photography/minimalist.jpg",
    category: "interior",
  },
  {
    title: "Modern Arabic Style",
    type: "in Modern Arabic style",
    content:
      "모던 아라비안(아랍) 인테리어 디자인은 아라비안 문화와 전통을 현대적으로 재해석한 스타일로, 화려한 색상과 패턴, 고급스러운 소재를 사용하여 화려하고 세련된 분위기를 조성합니다.",
    url: "/photography/modern_arabic.jpg",
    category: "interior",
  },
  {
    title: "Futuristic Style",
    type: "in Futuristic style",
    content:
      "퓨처리스틱(Futuristic) 인테리어 디자인은 과학적인 요소와 혁신적인 기술을 반영한 스타일로, 현대적이고 선진적인 분위기를 조성합니다. 이 스타일은 고급스러운 소재와 현대적인 디자인을 사용하여 미래적인 느낌을 연출합니다.",
    url: "/photography/futuristic.jpg",
    category: "interior",
  },
  {
    title: "Biophilic Style",
    type: "in Biophilic style",
    content:
      "바이오피릭(Biophilic) 인테리어 디자인은 자연 소재와 식물, 자연적인 요소를 활용하여 자연과의 조화로운 분위기를 조성합니다. 이 스타일은 건강하고 편안한 환경을 조성하며, 자연과의 연결을 강조합니다.",
    url: "/photography/biophilic.jpg",
    category: "interior",
  },
  {
    title: "Cottagecore Style",
    type: "in Cottagecore style",
    content:
      "코티지코어(Cottagecore) 인테리어 디자인은 시골 생활과 자연을 연상시키는 스타일로, 따뜻하고 아늑한 분위기를 조성합니다. 자연 소재와 부드러운 색상, 빈티지한 소품을 사용하여 노멀한 분위기를 조성합니다.",
    url: "/photography/cottagecore.jpg",
    category: "interior",
  },
  {
    title: "Tropical Style",
    type: "in Tropical style",
    content:
      "트로피컬(Tropical) 인테리어 디자인은 열대 지방의 자연과 문화를 반영한 스타일로, 밝고 생기 넘치는 분위기를 조성합니다. 이 스타일은 화려한 색상과 패턴, 식물을 활용하여 열대의 느낌을 연출합니다.",
    url: "/photography/tropical.jpg",
    category: "interior",
  },
  {
    title: "Vintage Style",
    type: "in Vintage style",
    content:
      "빈티지(Vintage) 인테리어 디자인은 과거의 느낌과 분위기를 연출하는 스타일로, 빈티지한 소품과 가구, 고전적인 패턴과 색상을 사용하여 고전적이고 로맨틱한 분위기를 조성합니다.",
    url: "/photography/vintage.jpg",
    category: "interior",
  },
  {
    title: "Retro Style",
    type: "in Retro style",
    content:
      "레트로(Retro) 인테리어 디자인은 과거의 느낌과 분위기를 연출하는 스타일로, 레트로한 소품과 가구, 고전적인 패턴과 색상을 사용하여 고전적이고 로맨틱한 분위기를 조성합니다.",
    url: "/photography/retro.jpg",
    category: "interior",
  },
  {
    title: "Cosmic Chic Style",
    type: "in Cosmic Chic style",
    content:
      "코스믹 시크(Cosmic Chic) 인테리어 디자인은 우주적인 느낌과 분위기를 연출하는 스타일로, 우주적인 패턴과 색상, 현대적인 소재를 사용하여 현대적이고 신비로운 분위기를 조성합니다.",
    url: "/photography/cosmic_chic.jpg",
    category: "interior",
  },
  {
    title: "Mexican Hacienda Style",
    type: "in Mexican Hacienda style",
    content:
      "멕시칸 하시엔다(Mexican Hacienda) 인테리어 디자인은 멕시코의 전통적인 느낌과 분위기를 연출하는 스타일로, 화려한 색상과 패턴, 고전적인 소재를 사용하여 열정적이고 화려한 분위기를 조성합니다.",
    url: "/photography/mexican_hacienda.jpg",
    category: "interior",
  },
  {
    title: "Apartment",
    type: "apartment",
    content:
      "아파트는 다세대 주택의 일종으로, 여러 가구가 한 건물 내에 거주하는 주택 형태를 말합니다. 아파트는 주로 도시의 주거 공간으로 사용되며, 다양한 크기와 디자인의 아파트가 있습니다.",
    url: "/photography/apartment.jpg",
    category: "exterior",
  },
  {
    title: "Single-Family Home",
    type: "single-family home",
    content:
      "단독 주택(Single-Family Home)은 한 가족이 거주하는 독채 주택을 말합니다. 이러한 주택은 주로 주택 지역이나 교외 지역에 위치하며, 한 가족이 독립적으로 생활할 수 있는 공간을 제공합니다.",
    url: "/photography/single_family_home.jpg",
    category: "exterior",
  },
  {
    title: "Multi-Family House",
    type: "multi-family House",
    content:
      "다세대 주택(Multi-Family House)은 여러 가구가 한 건물 내에 거주하는 주택 형태를 말합니다. 이러한 주택은 주로 도시의 주거 공간으로 사용되며, 여러 가구가 공동으로 사용하는 시설이 있을 수 있습니다.",
    url: "/photography/multi_family_house.jpg",
    category: "exterior",
  },
  {
    title: "Villa",
    type: "villa",
    content:
      "빌라(Villa)는 주로 휴가나 휴식을 취할 목적으로 사용되는 고급 주택을 말합니다. 빌라는 주로 시골이나 해변가에 위치하며, 개인적인 공간과 편안함을 제공합니다.",
    url: "/photography/villa.jpg",
    category: "exterior",
  },
  {
    title: "Townhouse",
    type: "townhouse",
    content:
      "타운하우스(Townhouse)는 도시나 마을의 주택으로, 다른 주택과 연결되어 있는 주택을 말합니다. 타운하우스는 주로 도시의 주거 공간으로 사용되며, 다양한 크기와 디자인의 타운하우스가 있습니다.",
    url: "/photography/townhouse.jpg",
    category: "exterior",
  },
  {
    title: "Hotel",
    type: "hotel",
    content:
      "호텔은 숙박 시설을 제공하는 공간으로, 여행이나 비즈니스 출장 등의 목적으로 사용됩니다. 호텔은 다양한 시설과 서비스를 제공하여 편안하고 안락한 숙박 환경을 제공합니다.",
    url: "/photography/hotel.jpg",
    category: "exterior",
  },
  {
    title: "Condominium",
    type: "condominium",
    content:
      "콘도미니엄(Condominium)은 다세대 주택의 일종으로, 여러 가구가 한 건물 내에 거주하는 주택 형태를 말합니다. 콘도미니엄은 주로 도시의 주거 공간으로 사용되며, 여러 가구가 공동으로 사용하는 시설이 있을 수 있습니다.",
    url: "/photography/condominium.jpg",
    category: "exterior",
  },
  {
    title: "Studio Apartment",
    type: "studio apartment",
    content:
      "오피스텔(Studio Apartment)은 주로 한 명 또는 두 명의 사람이 거주하는 작은 주택 형태를 말합니다. 오피스텔은 주방, 거실, 침실이 하나로 통합된 형태로, 작은 공간을 효율적으로 활용합니다.",
    url: "/photography/studio_apartment.jpg",
    category: "exterior",
  },
  {
    title: "Duplex",
    type: "duplex",
    content:
      "듀플렉스(Duplex)는 두 층으로 구성된 주택 형태를 말합니다. 이러한 주택은 주로 두 개의 주거 공간이 상하로 연결되어 있으며, 두 개의 가구가 독립적으로 생활할 수 있는 공간을 제공합니다.",
    url: "/photography/duplex.jpg",
    category: "exterior",
  },
  {
    title: "Dormitory",
    type: "dormitory",
    content:
      "기숙사(Dormitory)는 학생이나 교직원 등을 위한 생활 시설로, 다수의 개인이 함께 생활하는 주택 형태를 말합니다. 기숙사는 주로 학교나 대학교 교내에 위치하며, 학생들의 생활 편의를 위한 시설이 제공됩니다.",
    url: "/photography/dormitory.jpg",
    category: "exterior",
  },
  {
    title: "Resort",
    type: "resort",
    content:
      "리조트(Resort)는 휴양과 휴식을 취할 수 있는 숙박 시설을 제공하는 공간으로, 해변이나 산 중턱 등 자연환경에 위치한 숙박 시설을 말합니다. 리조트는 다양한 휴양 시설과 서비스를 제공하여 편안하고 즐거운 휴가를 즐길 수 있도록 합니다.",
    url: "/photography/resort.jpg",
    category: "exterior",
  },
  {
    title: "Loft",
    type: "loft",
    content:
      "로프트(Loft)는 공간을 활용한 주택 형태로, 높은 천장과 개방적인 구조를 가진 주택을 말합니다. 로프트는 주로 공장이나 창고를 개조하여 사용하며, 개인적인 공간과 작업 공간을 통합하여 제공합니다.",
    url: "/photography/loft.jpg",
    category: "exterior",
  },
  {
    title: "Commercial Building",
    type: "commercial building",
    content:
      "상업용 건물(Commercial Building)은 상업적인 목적을 위해 사용되는 건물을 말합니다. 이러한 건물은 사무실, 상점, 호텔, 레스토랑 등 다양한 업종의 시설을 제공하며, 도시의 상업 지역에 위치합니다.",
    url: "/photography/commercial_building.jpg",
    category: "exterior",
  },
  {
    title: "Warehouse",
    type: "warehouse",
    content:
      "창고(Warehouse)는 상품이나 물품을 보관하는 공간을 말합니다. 창고는 주로 공장이나 물류 센터 등에서 사용되며, 다양한 물품을 보관하고 관리하는 시설을 제공합니다.",
    url: "/photography/warehouse.jpg",
    category: "exterior",
  },
  {
    title: "Factory",
    type: "factory",
    content:
      "공장(Factory)은 제조업이나 생산 활동을 위해 사용되는 건물을 말합니다. 공장은 다양한 제품을 생산하거나 가공하는 시설을 제공하며, 생산 공정과 생산 라인을 운영하는데 사용됩니다.",
    url: "/photography/factory.jpg",
    category: "exterior",
  },
  {
    title: "School",
    type: "school",
    content:
      "학교(School)는 학생들이 교육을 받는 시설을 말합니다. 학교는 다양한 교육 활동을 지원하며, 학생들의 학습과 성장을 도모하는 시설을 제공합니다.",
    url: "/photography/school.jpg",
    category: "exterior",
  },
  {
    title: "Church",
    type: "church",
    content:
      "교회(Church)는 기독교 신자들이 예배를 드리거나 종교적인 행사를 거행하는 시설을 말합니다. 교회는 종교적인 의미와 역사적인 가치를 지니며, 다양한 종류의 교회 건물이 있습니다.",
    url: "/photography/church.jpg",
    category: "exterior",
  },
  {
    title: "Library",
    type: "library",
    content:
      "도서관(Library)은 도서와 자료를 보관하고 대중에게 대여하는 시설을 말합니다. 도서관은 지식과 문화를 보급하며, 독서와 학습을 도모하는 시설을 제공합니다.",
    url: "/photography/library.jpg",
    category: "exterior",
  },
];

function Home() {
  const userLocalLang = navigator.language || navigator.userLanguage;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const naverClientID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, login, logout, checkLogin } = useAuth();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [showLogo, setShowLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [beatLoading, setBeatLoading] = useState(false);
  const [imageType, setImageType] = useState(imageTypes[0].type);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [requestText, setRequestText] = useState("");
  const [effect, setEffect] = useState("");
  const [assistantMessage, setAssistantMessage] = useState("");
  const [imageList, setImageList] = useState([]);
  const [activeCategory, setActiveCategory] = useState("photography");
  const [userCount, setUserCount] = useState(0);
  const btnModalRef = useRef();
  // const googleLoginRef = useRef();

  const getPrompt = async () => {
    // const prompt = await $post("/api/prompt/midjourney", {
    //   imageType,
    //   aspectRatio,
    //   requestText,
    // });

    setAssistantMessage("");

    setBeatLoading(true);
    sessionStorage.setItem("stopAssistant", false);

    let url = `${serverUrl}/api/prompt/midjourney`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        imageType,
        aspectRatio,
        requestText,
        effect,
      }),
    });

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantMessage((prev) => prev + value);
    }

    sessionStorage.setItem("stopAssistant", true);
    setBeatLoading(false);

    setAssistantMessage($convertMarkdownToHtml(tempAssistantMessage.join("")));
  };

  const stopGenerating = () => {
    sessionStorage.setItem("stopAssistant", true);
  };

  const saveLanguage = async (lang) => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: t("언어를 변경하시겠습니까?"),
      text: t("자동 로그아웃 처리됩니다."),
      showCancelButton: true,
      confirmButtonText: t("네"),
      cancelButtonText: t("아니오"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/user/lang`, { lang: lang });
        if (res.status === 200) {
          socialLogout();
        }
      }
    });
  };

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: appUrl,
      scope: "profile_nickname, profile_image, account_email",
      //   state: "userme",
      //   serviceTerms: "account_email",
    });
  };

  const requestUserInfo = async (tokenInfo) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
      },
    });

    if (res.status === 401) {
      return;
    }

    const resJson = await res.json();

    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    const res2 = await $post("/api/kakao-login", {
      email: resJson.kakao_account.email,
      nickname: resJson.kakao_account.profile.nickname,
      profile_image_url: resJson.kakao_account.profile.profile_image_url,
      access_token: tokenInfo.access_token,
      access_token_expires_in: tokenInfo.expires_in,
      refresh_token: tokenInfo.refresh_token,
      refresh_token_expires_in: tokenInfo.refresh_token_expires_in,
      lang: userLanguage,
    });

    const userInfo = res2.data[0];
    setLoading(false);

    if (res2.status === 200) {
      login({
        ...userInfo,
      });

      checkLogin();

      // if (userInfo.role_level === 1) {
      //   if (userInfo.active_yn === "N") navigate("/waiting-list");
      // }
    }
  };

  const getToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&redirect_uri=${appUrl}&code=${code}`,
    });

    const resJson = await res.json();

    if (resJson.access_token) {
      window.Kakao.Auth.setAccessToken(resJson.access_token);
      requestUserInfo(resJson);
    }
  };

  const requestKaKaoInfo = async () => {
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get("code");
    if (code) {
      setLoading(true);
      getToken(code);
    }
  };

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const unlinkKakao = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      fetch("https://kapi.kakao.com/v1/user/unlink", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${res.accessToken}`,
        },
      })
        .then((res) => {
          window.Kakao.Auth.setAccessToken(null);
          logout();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not logged in.");
      return;
    }
  };

  const initKaKao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logoutWithKakao(res.accessToken);
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "NAVER") {
        logout();
      }
    }
  };

  // const onGoogleSuccess = async (response) => {
  //   const resData = jwtDecode(response.credential);
  //   let userLanguage = "EN";

  //   if (userLocalLang.startsWith("en")) {
  //     // 영어 사용자에 대한 처리
  //     userLanguage = "EN";
  //   } else if (userLocalLang.startsWith("ko")) {
  //     // 한국어 사용자에 대한 처리
  //     userLanguage = "KO";
  //   } else if (userLocalLang.startsWith("ja")) {
  //     // 일본어 사용자에 대한 처리
  //     userLanguage = "JP";
  //   } else if (userLocalLang.startsWith("de")) {
  //     // 독일어 사용자에 대한 처리
  //     userLanguage = "DE";
  //   } else if (userLocalLang.startsWith("fr")) {
  //     // 프랑스어 사용자에 대한 처리
  //     userLanguage = "FR";
  //   } else if (userLocalLang.startsWith("es")) {
  //     // 스페인어 사용자에 대한 처리
  //     userLanguage = "ES";
  //   } else if (userLocalLang.startsWith("nl")) {
  //     // 스페인어 사용자에 대한 처리
  //     userLanguage = "NL";
  //   } else if (userLocalLang.startsWith("sv")) {
  //     // 스페인어 사용자에 대한 처리
  //     userLanguage = "SV";
  //   } else if (userLocalLang.startsWith("ru")) {
  //     // 스페인어 사용자에 대한 처리
  //     userLanguage = "RU";
  //   }

  //   const res = await $post("/api/google-login", {
  //     // tokenId: resData.azp,
  //     email: resData.email,
  //     nickname: resData.name,
  //     profile_image_url: resData.picture,
  //     access_token_expires_in: resData.exp,
  //     lang: userLanguage,
  //   });
  //   // 서버로 토큰 전송

  //   const userInfo = res.data[0];
  //   // setLoading(false);

  //   if (res.status === 200) {
  //     login({
  //       ...userInfo,
  //     });
  //   }
  // };

  // const onGoogleFailure = (response) => {
  //   console.log("Login Failed:", response);
  // };

  // const loginWithGoogle = async () => {
  //   console.log(`${serverUrl}/api/google-login`);
  //   let url = "https://accounts.google.com/o/oauth2/v2/auth";
  //   // client_id는 위 스크린샷을 보면 발급 받았음을 알 수 있음
  //   // 단, 스크린샷에 있는 ID가 아닌 당신이 직접 발급 받은 ID를 사용해야 함.
  //   url += `?client_id=${googleClientId}`;
  //   // 아까 등록한 redirect_uri
  //   // 로그인 창에서 계정을 선택하면 구글 서버가 이 redirect_uri로 redirect 시켜줌
  //   url += `&redirect_uri=${serverUrl}/api/google-login`;
  //   // 필수 옵션.
  //   url += "&response_type=code";
  //   // 구글에 등록된 유저 정보 email, profile을 가져오겠다 명시
  //   url += "&scope=email profile";
  //   // 완성된 url로 이동
  //   // 이 url이 위에서 본 구글 계정을 선택하는 화면임.

  //   // 브라우저 url을 이동
  //   document.location.href = url;
  // };

  const setI18nLanguage = () => {
    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    i18n.changeLanguage(userLanguage);
  };

  // const getUserCount = async () => {
  //   const res = await $get("/api/user/count");
  //   if (res.status === 200) {
  //     setUserCount(res.data[0].count);
  //   }
  // };

  const checkRemainActiveDate = () => {
    if (userData && userData.active_end_date) {
      const currentDate = new Date();
      const activeEndDate = new Date(userData.active_end_date);
      if (currentDate < activeEndDate) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    initKaKao();
    requestKaKaoInfo();
    setI18nLanguage();

    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    return () => lightbox.destroy();
  }, []);

  return (
    <main>
      <section className="py-4 text-center">
        <div className="row">
          <div className="col-12 mx-auto">
            <p className="lead fw-bold">
              {t(
                "I(Image)dot은 제품, 모델, 목업, 로고, 일러스트, 사진에 이르는 전문적인 이미지를 생성할 수 있는 미드저니 프롬프트 AI 어시스턴트입니다."
              )}
            </p>
            {/* {isLoggedIn && (
              <button
                className="btn btn-dark me-2"
                onClick={() => socialLogout()}
              >
                {t("로그아웃")}
              </button>
            )} */}

            {isLoggedIn && (
              <button
                className="btn btn-danger me-1 mb-1"
                data-bs-toggle="modal"
                data-bs-target="#languageModal"
              >
                {t("언어 변경")}
              </button>
            )}
            {userLocalLang.startsWith("ko") && (
              <a
                href="https://youtu.be/pg8ejyGeEEI"
                className="btn btn-dark me-1 glightbox mb-1"
              >
                {t("미드저니 가입 및 세팅하기")}
              </a>
            )}

            {userLocalLang.startsWith("ko") && (
              <a
                href="https://youtu.be/BRAmKkLyOVk"
                className="btn btn-dark me-1 glightbox mb-1"
              >
                {t("미드저니 기본기능 사용법")}
              </a>
            )}

            {checkRemainActiveDate() && (
              <div className="mt-2">
                {userData.subscription_yn === "N" && (
                  <p className="text-center">
                    {t("사용 만료일")} :{" "}
                    {new Date(userData.active_end_date).toLocaleString()}
                  </p>
                )}
              </div>
            )}
            {!isLoggedIn && (
              <div className="pb-3">
                {appUrl.includes("localhost") && (
                  <a
                    onClick={() => loginWithKakao()}
                    className="mb-1"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src="./assets/images/kakao-login-button.png"
                      width="300px"
                      height="auto"
                      alt="카카오 로그인 버튼"
                    />
                  </a>
                )}
                {/* <div
                  className="google-login"
                  style={{ display: "none" }}
                  ref={googleLoginRef}
                >
                  {!navigator.userAgent.includes("Safari") && (
                    <GoogleOAuthProvider clientId={googleClientId}>
                      <GoogleLogin
                        onSuccess={onGoogleSuccess}
                        onError={onGoogleFailure}
                        size="large"
                        shape="rectangular"
                        logo_alignment="left"
                        type="standard"
                        width="300px"
                        height="52px"
                        useOneTap={false}
                      />
                    </GoogleOAuthProvider>
                  )}
                </div>
                {userLocalLang === "ko-KR" ? (
                  <img
                    className="google-login-button mt-1"
                    src="./assets/images/google-login-button.png"
                    style={{ cursor: "pointer" }}
                    width="300px"
                    height="auto"
                    alt="구글 로그인 버튼"
                    onClick={() => {
                      if (navigator.userAgent.includes("Safari")) {
                        loginWithGoogle();
                      } else {
                        googleLoginRef.current
                          .querySelector("[role=button]")
                          .click();
                      }
                    }}
                  />
                ) : (
                  <img
                    className="google-login-button mt-1"
                    src="./assets/images/google-login-button-en.png"
                    style={{ cursor: "pointer" }}
                    width="300px"
                    height="auto"
                    alt="구글 로그인 버튼"
                    onClick={() => {
                      if (navigator.userAgent.includes("Safari")) {
                        loginWithGoogle();
                      } else {
                        googleLoginRef.current
                          .querySelector("[role=button]")
                          .click();
                      }
                    }}
                  />
                )}

                <a
                  onClick={() => {
                    Swal.fire({
                      iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
                      title: t("지금 무료로 시작하기"),
                      text: t("3일간 무료 이용이 가능합니다."),
                      showCancelButton: true,
                      confirmButtonText: t("네"),
                      cancelButtonText: t("아니오"),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        if (navigator.userAgent.includes("Safari")) {
                          loginWithGoogle();
                        } else {
                          googleLoginRef.current
                            .querySelector("[role=button]")
                            .click();
                        }
                      }
                    });
                  }}
                  className="btn btn-danger ms-1 mt-1"
                  style={{
                    cursor: "pointer",
                    width: "300px",
                    height: "52px",
                    fontSize: "1.1rem",
                    paddingTop: "12px",
                  }}
                >
                  {t("지금 무료로 시작하기")}
                </a> */}
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="album py-5 bg-body-tertiary">
        <div className="p-4">
          <nav className="nav nav-pills nav-fill mb-3 nav-category">
            <a
              className={
                activeCategory === "photography"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => setActiveCategory("photography")}
            >
              Photography
            </a>
            <a
              className={
                activeCategory === "illustration"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => setActiveCategory("illustration")}
            >
              Illustration/drawing
            </a>
            <a
              className={
                activeCategory === "portrait" ? "nav-link active" : "nav-link"
              }
              onClick={() => setActiveCategory("portrait")}
            >
              Portrait
            </a>
            {/* <a
              className={
                activeCategory === "drawing" ? "nav-link active" : "nav-link"
              }
              onClick={() => setActiveCategory("drawing")}
            >
              drawing
            </a> */}
            <a
              className={
                activeCategory === "interior" ? "nav-link active" : "nav-link"
              }
              onClick={() => {
                setActiveCategory("interior");
              }}
            >
              Interior
            </a>
            <a
              className={
                activeCategory === "exterior" ? "nav-link active" : "nav-link"
              }
              onClick={() => setActiveCategory("exterior")}
            >
              Exterior
            </a>
          </nav>
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-3">
            <button
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#promptModal"
              ref={btnModalRef}
              style={{ display: "none" }}
            ></button>
            {imageTypes.map((imageType, index) => (
              <div
                className="col"
                key={`imagetype-${index}`}
                style={{
                  display: imageType.category.includes(activeCategory)
                    ? "block"
                    : "none",
                }}
              >
                <div
                  className="card shadow-sm"
                  onClick={async (e) => {
                    if (isLoggedIn && userData && userData.active_yn === "Y") {
                      setRequestText("");
                      setAssistantMessage("");
                      setImageType(imageType.type);
                      setEffect(imageType.effect || "");
                      const bCheck = await checkLogin();
                      if (bCheck) {
                        btnModalRef.current.click();
                      }
                    } else {
                      Swal.fire({
                        iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
                        title: t("구독이 필요한 서비스입니다."),
                        showCancelButton: true,
                        confirmButtonText: t("구독하러 가기"),
                        cancelButtonText: t("확인"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.open(
                            "https://www.wonderslab.ai/pricing",
                            "_blank"
                          );
                        }
                      });
                    }
                  }}
                >
                  <img
                    src={`./assets/images${imageType.url}`}
                    className="bd-placeholder-img card-img-top"
                    width="auto"
                    height="225px"
                  />

                  <div className="card-body" style={{ cursor: "pointer" }}>
                    <p className="fw-bold">{imageType.title}</p>
                    <p className="card-text">{t(imageType.content)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="promptModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="promptModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="promptModalLabel">
                Prompt
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={(e) => {
                //   promptModal.hide();
                // }}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <div className="input-group mb-3">
                <div className="input-group-text">
                  <select
                    className="form-select"
                    value={aspectRatio}
                    onChange={(e) => setAspectRatio(e.target.value)}
                  >
                    <option value="1:1">1:1</option>
                    <option value="3:2">3:2</option>
                    <option value="4:3">4:3</option>
                    <option value="16:9">16:9</option>
                    <option value="2:3">2:3</option>
                    <option value="3:4">3:4</option>
                    <option value="9:16">9:16</option>
                  </select>
                </div>
                <input
                  type="search"
                  className="form-control"
                  placeholder={t("이미지 설명 또는 키워드를 입력하세요.")}
                  value={requestText}
                  onChange={(e) => setRequestText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (e.nativeEvent.isComposing) {
                        return;
                      }
                      if (requestText === "") return;
                      getPrompt(e);
                    }
                  }}
                />
              </div>
              {/* <div className="btn-group">
                <button type="button" className="btn btn-outline-secondary">
                  {t("front angle")}
                </button>
                <button type="button" className="btn btn-outline-secondary">
                  {t("back angle")}
                </button>
                <button type="button" className="btn btn-outline-secondary">
                  {t("birds-eye angle")}
                </button>
              </div> */}
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{ __html: assistantMessage }}
              ></div>
            </div>
            <div className="modal-footer">
              {beatLoading && <BeatLoader color="#f2295b" />}
              {!beatLoading && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => getPrompt()}
                >
                  {t("생성하기")}
                </button>
              )}
              {beatLoading && (
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => stopGenerating()}
                >
                  <i className="fa-regular fa-circle-stop"></i> {t("중지하기")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="languageModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="languageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="languageModalLabel">
                {t("언어 변경")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: "center" }}>
              {userData && (
                <div className="row g-2">
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "EN" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("EN")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      English(EN)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "KO" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("KO")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      한국어(KO)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "JP" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("JP")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      日本語(JP)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "FR" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("FR")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Français(FR)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "ES" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("ES")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Español(ES)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "DE" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("DE")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Deutsch(DE)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "IT" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("IT")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Italiano(IT)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "RU" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("RU")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Русский(RU)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "NL" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => saveLanguage("NL")}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Nederlands(NL)
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
