function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
        <p className="col-md-4 mb-0 text-body-secondary">
          © {currentYear} WondersLab, Inc
        </p>

        <img src="./assets/images/idot-logo.png" alt="logo" className="logo" />

        <ul className="nav col-md-4 justify-content-end">
          <li className="nav-item">
            <a
              href="https://www.wonderslab.ai"
              target="_blank"
              className="nav-link px-2 text-body-secondary"
            >
              WondersLab
            </a>
          </li>
          <li className="nav-item">
            <a
              href="https://wdot.wonderslab.ai"
              target="_blank"
              className="nav-link px-2 text-body-secondary"
            >
              Wdot
            </a>
          </li>
          <li className="nav-item">
            <a
              href="https://sdot.wonderslab.ai"
              target="_blank"
              className="nav-link px-2 text-body-secondary"
            >
              Sdot
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default Footer;
