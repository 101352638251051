import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";

function CompanyList() {
  const navigate = useNavigate();
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();

  const { isLoggedIn, companyData, checkLogin } = useAuth();

  const [companyList, setCompanyList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getCompanyList = async () => {
    setLoading(true);

    const apiURL =
      searchText === ""
        ? `/api/company/list/${pageNo}/${pageLimit}`
        : `/api/company/list/${pageNo}/${pageLimit}?search=${encodeURIComponent(
            searchText
          )}`;
    const res = await $get(apiURL);

    // console.log(res);
    if (res.status === 200) {
      setCompanyList(res.data.companies);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "기업 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const apiURL =
      searchText === ""
        ? `/api/company/list/${pageNo}/${pageLimit}`
        : `/api/company/list/${pageNo}/${pageLimit}?search=${encodeURIComponent(
            searchText
          )}`;
    const res = await $get(apiURL);
    // console.log(res);
    if (res.status === 200) {
      setCompanyList(res.data.companies);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "기업 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const deleteCompany = async (id) => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: "기업 삭제",
      text: "기업을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/company/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "기업 삭제 성공",
            text: "기업이 삭제되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          getCompanyList();
        } else {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "기업 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const goToDetail = (company_id) => {
    navigate("/companydetail", { state: { company_id } });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (companyData && companyData.role_level !== 0) {
      navigate("/401");
    }
  }, [companyData]);

  useEffect(() => {
    // checkLogin();
    // getCompanyList();
  }, []);

  return (
    <div className="container py-5">
      <div className="content">
        <div className="d-flex justify-content-between mb-3">
          <h3>{t("기업 관리")}</h3>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => navigate("/companyinsert")}
          >
            기업 등록
          </button>
        </div>
        <div className="search-box mb-3">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="기업명을 입력하세요."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" && getCompanyList();
              }}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => getCompanyList()}
            >
              <i className="fa-solid fa-search"></i>
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover table-bordered table-striped table-nowrap">
            <thead>
              <tr>
                <th>기업명</th>
                <th>비즈니스번호</th>
                <th>담당자</th>
                <th>연락처</th>
                <th>이메일</th>
                <th>국가</th>
                <th width="90px">상태</th>
                <th>등록 일시</th>
              </tr>
            </thead>
            <tbody>
              {companyList &&
                companyList.map((company, idx) => (
                  <tr key={`company-${idx}`}>
                    <td>
                      <a
                        onClick={() => goToDetail(company.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {company.company_name}
                      </a>
                    </td>
                    <td>{company.business_no}</td>
                    <td>{company.person_in_charge}</td>
                    <td>{company.tel}</td>
                    <td>{company.email}</td>
                    <td>{company.country}</td>
                    <td>{company.active_yn === "Y" ? "활성" : "비활성"}</td>
                    <td>{$dateTimezone(company.create_datetime)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {totalPage > 1 && (
            <div className="d-flex justify-content-center mt-10">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {pageNo > 5 && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={(e) => setPageNo(pageNo - 1)}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </a>
                    </li>
                  )}

                  {pageRange.map((page) => (
                    <li key={page}>
                      <a
                        className={
                          pageNo === page ? "page-link active" : "page-link"
                        }
                        onClick={(e) => setPageNo(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                  {lastPage < totalPage && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={(e) => setPageNo(pageNo + 1)}
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>

      {loading && <Loading />}
    </div>
  );
}

export default CompanyList;
