import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";

function UserList() {
  const navigate = useNavigate();
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { isLoggedIn, userData, checkLogin } = useAuth();
  const { listPageNo } = location.state || { listPageNo: 1 };

  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(listPageNo);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getUserList = async () => {
    setLoading(true);

    const apiURL =
      searchText === ""
        ? `/api/user/list/${pageNo}/${pageLimit}`
        : `/api/user/list/${pageNo}/${pageLimit}?search=${encodeURIComponent(
            searchText
          )}`;
    const res = await $get(apiURL);

    // console.log(res);
    if (res.status === 200) {
      setUserList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const apiURL =
      searchText === ""
        ? `/api/user/list/${pageNo}/${pageLimit}`
        : `/api/user/list/${pageNo}/${pageLimit}?search=${encodeURIComponent(
            searchText
          )}`;
    const res = await $get(apiURL);
    // console.log(res);
    if (res.status === 200) {
      setUserList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const deleteUser = async (id) => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: "사용자 삭제",
      text: "사용자을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/user/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "사용자 삭제 성공",
            text: "사용자이 삭제되었습니다.",
            showConfirmButton: false,
            timer: 1000,
          });
          getUserList();
        } else {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "사용자 삭제 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const goToDetail = (email) => {
    navigate("/userdetail", { state: { email, listPageNo: pageNo } });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    // checkLogin();
    // getUserList();
  }, []);

  return (
    <div className="container py-5">
      <div className="content">
        <h3>{t("사용자 관리")}</h3>
        <div className="search-box mb-10">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="닉네임 또는 이메일 주소를 입력하세요."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" && getUserList();
              }}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => getUserList()}
            >
              <i className="fa-solid fa-search"></i>
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover table-striped table-bordered table-nowrap">
            <thead>
              <tr>
                <th>닉네임</th>
                <th>이름</th>
                <th>이메일</th>
                <th>언어</th>
                <th width="90px">구독여부</th>
                <th width="90px">상태</th>
                <th>사용량</th>
                <th>등록 일시</th>
              </tr>
            </thead>
            <tbody>
              {userList &&
                userList.map((user, idx) => (
                  <tr key={`user-${idx}`}>
                    <td>
                      <a
                        onClick={() => goToDetail(user.email)}
                        style={{ cursor: "pointer" }}
                      >
                        {user.nickname}
                      </a>
                    </td>
                    <td>{user.name}</td>
                    <td>
                      <a
                        onClick={() => goToDetail(user.email)}
                        style={{ cursor: "pointer" }}
                      >
                        {user.email}
                      </a>
                    </td>
                    <td>{user.lang}</td>
                    <td>{user.subscription_yn}</td>
                    <td>{user.active_yn === "Y" ? "활성" : "비활성"}</td>
                    <td>{$convertNumberFormat(user.total_tokens)}</td>
                    <td>{$dateTimezone(user.create_datetime)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {totalPage > 1 && (
            <div className="d-flex justify-content-center mt-10">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {pageNo > 5 && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={(e) => setPageNo(pageNo - 1)}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </a>
                    </li>
                  )}

                  {pageRange.map((page) => (
                    <li key={page}>
                      <a
                        className={
                          pageNo === page ? "page-link active" : "page-link"
                        }
                        onClick={(e) => setPageNo(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                  {lastPage < totalPage && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={(e) => setPageNo(pageNo + 1)}
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>

      {loading && <Loading />}
    </div>
  );
}

export default UserList;
