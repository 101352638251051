import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import translationEN from "./en/translation.json";
import translationKO from "./ko/translation.json";
import translationJP from "./jp/translation.json";
import translationDE from "./de/translation.json";
import translationFR from "./fr/translation.json";
import translationES from "./es/translation.json";

const resources = {
  EN: {
    translation: translationEN,
  },
  KO: {
    translation: translationKO,
  },
  JP: {
    translation: translationJP,
  },
  DE: {
    translation: translationDE,
  },
  FR: {
    translation: translationFR,
  },
  ES: {
    translation: translationES,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: "en", // 기본 설정 언어, 'cimode'로 설정할 경우 키 값으로 출력된다.
    fallbackLng: "en", // 번역 파일에서 찾을 수 없는 경우 기본 언어
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
