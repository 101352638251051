import { useTranslation } from "react-i18next";

function InternalServerError() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container p-30">
      <h1>
        500 - {t("서비스를 이용할 수 없습니다. 잠시 후 다시 시도하세요.")}
      </h1>
    </div>
  );
}

export default InternalServerError;
