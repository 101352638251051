import { useTranslation } from "react-i18next";

function NotAuthorized() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <h1>401 - Not Authorized ({t("페이지에 대한 접근 권한이 없습니다.")})</h1>
    </div>
  );
}

export default NotAuthorized;
