import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $get, $convertMarkdownToHtml } from "../utils/common";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Loading from "../component/Loading";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

const imageTypes = [
  {
    title: "Gold and White",
    type: "modern_gold_white",
    items: [
      {
        href: "/interior/modern/gold_white_kitchen_01.png",
        title: "Kitchen",
        type: "image",
        prompt:
          "Interior photography of a kitchen, in Modern style, featuring gold pendant lights and gold and white color scheme, in a luxury home, with natural and ambient lighting, shot with a wide-angle lens, using Canon EF 16-35mm, including close-up details of the pendant lights and marble countertops --ar 16:9",
      },
      {
        href: "/interior/modern/gold_white_kitchen_02.png",
        title: "Kitchen",
        type: "image",
        prompt:
          "Interior photography of a kitchen, in Modern style, featuring gold pendant lights and gold and white color scheme, in a luxury home, with natural and ambient lighting, shot with a wide-angle lens, using Canon EF 16-35mm, including close-up details of the pendant lights and marble countertops --ar 16:9",
      },
      {
        href: "/interior/modern/gold_white_dressroom_01.png",
        title: "Dress Room",
        type: "image",
        prompt:
          "Interior photography of a dressing room, in Modern style, featuring gold and white built-in wardrobes, a vanity, and hardwood floors, in a residential setting, illuminated by yellow lights, shot with a wide-angle lens, using Canon EF 11-24mm f/4L, emphasizing the spaciousness and luxury of the space --ar 16:9",
      },
      {
        href: "/interior/modern/gold_white_bathroom_01.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a bathroom, in Modern style, highlighting a white marble design with gold-framed mirror, gold showerhead, gold-framed shower booth, and a white toilet with gold lever, in a luxury home, under bright natural light, shot with a wide-angle lens, using Canon EF 16–35mm f/2.8L III USM, showcasing a minimalist elegance, from a slightly elevated perspective --ar 16:9",
      },
    ],
    category: "modern",
  },
  {
    title: "Black and White",
    type: "modern_black_white",
    items: [
      {
        href: "/interior/modern/black_white_bathroom_01.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a bathroom, in Modern style, featuring a white bathtub, black cabinet, and white sink, in a luxurious home, soft natural lighting, shot with a wide-angle lens, using Canon EF 16-35mm f/4L IS USM, clean and minimal composition --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_bathroom_02.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a bathroom, in Modern style, featuring a white bathtub, black cabinet, and white sink, in a luxurious home, soft natural lighting, shot with a wide-angle lens, using Canon EF 16-35mm f/4L IS USM, clean and minimal composition --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_bathroom_03.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a bathroom in Modern style featuring a shower booth and a black cabinet with a white sink and black floor tiles in a softly lit home using a wide-angle lens capturing the smooth contrasts and sleek lines --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_bathroom_04.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a bathroom in Modern style featuring a shower booth and a black cabinet with a white sink and black floor tiles in a softly lit home using a wide-angle lens capturing the smooth contrasts and sleek lines --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_kitchen_01.png",
        title: "Kitchen",
        type: "image",
        prompt:
          "Interior photography of a kitchen, in Modern style, featuring a white and black color scheme with black pendant lights, black refrigerator, oven, and black dishwasher, in a domestic setting, natural light filtering through large windows, shot with a wide-angle lens, using Canon EF 16-35mm, including sharp foreground details, from a slightly elevated perspective --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_kitchen_02.png",
        title: "Kitchen",
        type: "image",
        prompt:
          "Interior photography of a kitchen, in Modern style, featuring a white and black color scheme with black pendant lights, black refrigerator, oven, and black dishwasher, in a domestic setting, natural light filtering through large windows, shot with a wide-angle lens, using Canon EF 16-35mm, including sharp foreground details, from a slightly elevated perspective --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_kitchen_03.png",
        title: "Kitchen",
        type: "image",
        prompt:
          "Interior photography of a kitchen, in Modern style, white cabinets with black accents including black pendant lights, black refrigerator, oven, and black dishwasher, in a residential apartment, ambient lighting complemented by pendant illumination, shot with a wide-angle lens, using Canon EF 16-35mm, including sharp foreground details, from a slightly elevated perspective --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_main_room_01.png",
        title: "Bedroom",
        type: "image",
        prompt:
          "Interior photography of a bedroom in Modern style featuring a black bed and a black dressing table against white walls with an inset lighting system and a black-and-white built-in wardrobe in a residential setting shot with a wide-angle lens using Canon EF 16-35mm f/2.8L II USM emphasizing space and contrast --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_main_room_02.png",
        title: "Bedroom",
        type: "image",
        prompt:
          "Interior photography of a master bedroom in Modern style featuring a black bed and black vanity against white walls soft recessed lighting shot with a wide-angle lens using Canon EF 16-35mm showcasing the contrasting furniture colors and the balcony with white curtains --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_main_room_03.png",
        title: "Bedroom",
        type: "image",
        prompt:
          "Interior photography of a master bedroom in Modern style featuring a black bed and black vanity against white walls soft recessed lighting shot with a wide-angle lens using Canon EF 16-35mm showcasing the contrasting furniture colors and the balcony with white curtains --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_study_library_01.png",
        title: "Bedroom",
        type: "image",
        prompt:
          "Interior photography of a study, in Modern style, featuring a black desk, white chair, white curtains, and black bookcase against white walls, in a suburban home, soft recessed lighting, shot with a wide-angle lens, using Canon EF 16-35mm, focused on the sharp contrast and clean lines --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_study_library_02.png",
        title: "Bedroom",
        type: "image",
        prompt:
          "Interior photography of a study, in Modern style, featuring a black desk, white chair, white curtains, and black bookcase against white walls, in a suburban home, soft recessed lighting, shot with a wide-angle lens, using Canon EF 16-35mm, focused on the sharp contrast and clean lines --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_study_library_03.png",
        title: "Bedroom",
        type: "image",
        prompt:
          "Interior photography of a study, in Modern style, featuring a black desk, white chair, white curtains, and black bookcase against white walls, in a suburban home, soft recessed lighting, shot with a wide-angle lens, using Canon EF 16-35mm, focused on the sharp contrast and clean lines --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_dressroom_01.png",
        title: "Dress Room",
        type: "image",
        prompt:
          "Interior photography of a dressing room, in Modern style, featuring white walls, black shelves, a white vanity, and a mirror, in a residential home, with soft, natural lighting, shot with a wide-angle lens, using Canon EF 16-35mm, with a clean, uncluttered composition, from a front-facing view --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_dressroom_02.png",
        title: "Dress Room",
        type: "image",
        prompt:
          "Interior photography of a dressing room, in Modern style, featuring white walls, black hangers, a black island cabinet, and a white vanity with a mirror, in a luxury home, natural light with soft shadows, shot with a wide-angle lens, using Canon EF 16-35mm, including close-ups of the vanity details, elevated view --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_dressroom_03.png",
        title: "Dress Room",
        type: "image",
        prompt:
          "Interior photography of a spacious dressing room, in Modern style, with white walls, a sleek black island cabinet, black hangers, and an elegant white makeup vanity with large mirror, in an urban apartment, bright indirect lighting, shot with a telephoto lens, using Sony FE 70-200mm f/2.8, capturing the full depth of the room, from a high angle --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_entrance_01.png",
        title: "Entrance",
        type: "image",
        prompt:
          "Interior photography of a foyer, in modern style, with a sleek black apartment door, adjacent white walls, a black framed window on the side, black hexagonal floor tiling, and a white built-in shoe rack, highlighted by sensor lights, shot with a wide-angle lens, using Canon EF 16-35mm, showing a full view of the space layout --ar 16:9",
      },
      {
        href: "/interior/modern/black_white_entrance_02.png",
        title: "Entrance",
        type: "image",
        prompt:
          "Interior photography of a foyer, in modern style, with a sleek black apartment door, adjacent white walls, a black framed window on the side, black hexagonal floor tiling, and a white built-in shoe rack, highlighted by sensor lights, shot with a wide-angle lens, using Canon EF 16-35mm, showing a full view of the space layout --ar 16:9",
      },
    ],
    category: "modern",
  },
  {
    title: "Wood and Rattan",
    type: "wood_and_rattan",
    items: [
      {
        href: "/interior/natural/wood_rattan_livingroom_01.png",
        title: "Living Room",
        type: "image",
        prompt:
          "Interior photography of a living room, in Contemporary style, featuring wood flooring with rattan and wood furniture, in a standalone house, natural light filtering through sheer curtains, shot with a wide-angle lens, using Canon EF 16-35mm, include soft textures and plush cushions, from a low angle viewpoint --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_livingroom_02.png",
        title: "Living Room",
        type: "image",
        prompt:
          "Interior photography of a living room, in Contemporary style, featuring wood flooring with rattan and wood furniture, in a standalone house, natural light filtering through sheer curtains, shot with a wide-angle lens, using Canon EF 16-35mm, include soft textures and plush cushions, from a low angle viewpoint --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_dressroom_01.png",
        title: "Dress Room",
        type: "image",
        prompt:
          "Interior photography of a standalone house dressing room, in Contemporary style, featuring wood and rattan shelves and wardrobes, in a spacious home, natural light filtering through sheer curtains, shot with a wide-angle lens, using Canon EF 11-24mm, emphasizing soft textures and earthy tones, from a front-facing perspective --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_dressroom_02.png",
        title: "Dress Room",
        type: "image",
        prompt:
          "Interior photography of a standalone house dressing room, in Contemporary style, featuring wood and rattan shelves and wardrobes, in a spacious home, natural light filtering through sheer curtains, shot with a wide-angle lens, using Canon EF 11-24mm, emphasizing soft textures and earthy tones, from a front-facing perspective --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_kitchen_01.png",
        title: "Kitchen",
        type: "image",
        prompt:
          "Interior photography of a standalone house kitchen, in Contemporary style, featuring wooden flooring, rattan lampshades, rattan table and chair set, and a wide door leading to the backyard, adorned with green plants, in a suburban setting, natural morning light, shot with a wide-angle lens, using Canon EF 16-35mm f/4L IS USM, positioned to include the play of light on the wooden elements --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_kitchen_02.png",
        title: "Kitchen",
        type: "image",
        prompt:
          "Interior photography of a single-family home kitchen, in Contemporary style, featuring a rattan chandelier, wooden floors, and a rattan dining set, with a wide door leading to the yard, several green plants, with soft natural lighting, shot with a wide-angle lens, using Canon EF 16-35mm, focused on the intricate texture of the rattan and the warmth of the wood --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_bathroom_01.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a private house bathroom, in Contemporary style, featuring a wood and rattan shower partition, rattan lamp shades, and rattan shelves, in a softly lit serene setting, shot with a wide-angle lens, using Canon EF 16-35mm, highlighting texture details, from a frontal viewing perspective --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_bathroom_02.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a bathroom in Contemporary style featuring a wooden and rattan shower partition rattan lampshade and rattan storage cabinet with a white bathtub and white sink in a modern home natural light streaming through frosted windows shot with a wide-angle lens using Canon EF 16-35mm focusing on texture and serenity of the space --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_bathroom_03.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a bathroom in Contemporary style featuring a wooden and rattan shower partition rattan lampshade and rattan storage cabinet with a white bathtub and white sink in a modern home natural light streaming through frosted windows shot with a wide-angle lens using Canon EF 16-35mm focusing on texture and serenity of the space --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_yard_01.png",
        title: "Yard",
        type: "image",
        prompt:
          "Interior photography of a backyard, in Contemporary style, featuring a green lawn with a rattan sunbed and a mini pool, in a standalone house, during golden hour, shot with a prime lens, using Canon EF 11-24mm f/4L, including a surrounding mini garden, from a high-angle view --ar 16:9",
      },
      {
        href: "/interior/natural/wood_rattan_yard_02.png",
        title: "Yard",
        type: "image",
        prompt:
          "Exterior photography of a detached house's backyard, featuring a green lawn, rattan sunbed, pool with floating tubes, circular rattan swing, and a mini garden, in a suburban setting, natural daylight conditions, shot with a wide-angle lens, using Canon EF 16-35mm, crisp focus on the pool area with a casual, all-inclusive viewing perspective --ar 16:9",
      },
    ],
    category: "natural",
  },
  {
    title: "White and Light Wood",
    type: "white_light_wood",
    items: [
      {
        href: "/interior/minimal/white_light_wood_livingroom.png",
        title: "Living Room",
        type: "image",
        prompt:
          "Interior photography of a living space, in Minimalist style, featuring a plush fabric sofa, flush-mounted TV, hardwood floors, and plain white walls, accentuated by sleek, embedded lights and minimalist decor, in an urban Korean apartment, with daylight streaming through balcony doors, shot with a telephoto lens, using Nikon 70-200mm, capturing the delicate interplay of light and shadow --ar 16:9",
      },
      {
        href: "/interior/minimal/white_light_wood_kitchen.png",
        title: "Kitchen",
        type: "image",
        prompt:
          "Interior photography of a minimalist kitchen in a Korean-style apartment, with white upper and lower cabinets, a cozy window positioned between, and a white fridge integrated into the cabinetry, in an urban Seoul setting, under the glow of a chic white pendant light, shot with a mirrorless camera lens, using Sony E 10-18mm, capturing the essence of a modern dining area and wooden floors in the simplicity of the design --ar 16:9",
      },
      {
        href: "/interior/minimal/white_light_wood_mainroom.png",
        title: "Bedroom",
        type: "image",
        prompt:
          "Interior photography of a compact bedroom in a Korean apartment, in Minimalist style, showcasing a white wood-panel bed frame, matched with white walls and wooden floor, a white wardrobe facing the bed, and a balcony set up with a table and chairs, in a serene home environment, illuminated by diffused daylight, shot with a zoom lens, using Sony 24-70mm f/2.8, capturing the entire room, overhead perspective --ar 16:9",
      },
      {
        href: "/interior/minimal/white_light_wood_studyroom.png",
        title: "Study Room",
        type: "image",
        prompt:
          "Interior photography of a simple home office in a minimalist, South Korean small apartment, showcasing white wallpaper, wooden floor, white integrated wardrobe, and a sleek study desk with chair, in a serene residential area, bright ambient light augmented by desk lamp, shot with a zoom lens, using Sony 24-70mm f/2.8, composition emphasizes the contrast between textures and colors, eye-level shot --ar 16:9",
      },
      {
        href: "/interior/minimal/white_light_wood_bathroom.png",
        title: "Bathroom",
        type: "image",
        prompt:
          "Interior photography of a restroom, in Minimalist style, displaying a shower booth adjacent to a sink, a toilet next to the sink, with white tiles and built-in shelving, in a contemporary home, enhanced by diffused artificial lighting, shot with a prime lens, using Nikon 24mm, capturing the contrast between the brick-style shower booth and the sleek surfaces --ar 16:9",
      },
      {
        href: "/interior/minimal/white_light_wood_utilityroom.png",
        title: "Utility Room",
        type: "image",
        prompt:
          "Interior photography of a utility room, in Minimalist style, featuring a balcony, white tiles and walls, a washing machine with a dryer stacked on top, in a modern apartment, natural daylight streaming in, shot with a wide-angle lens, using Canon EF 16-35mm, capturing the functional beauty and crisp lines, from a frontal viewpoint --ar 16:9",
      },
      {
        href: "/interior/minimal/white_light_wood_entrance.png",
        title: "Entrance",
        type: "image",
        prompt:
          "https://s.mj.run/qslYCx4VBRk Interior photography of a narrow foyer in Minimalist style featuring sleek white tiles and wood inset lights in a modern apartment ambient overhead lighting shot with a prime lens using Nikon 24mm capturing details like the white shoe storage from the entrance looking towards the interior --ar 16:9",
      },
    ],
    category: "minimalist",
  },
];

function Interior() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [activeCategory, setActiveCategory] = useState("modern");
  const [lightbox, setLightbox] = useState(null);

  const openGallery = (type, startAt) => {
    // const elements = imageTypes
    //   .filter((imageType) => imageType.type === type)[0]
    //   .items.map((item) => {
    //     return {
    //       href: `${appUrl}/assets/images${item.href}`,
    //       type: "image",
    //       draggable: false,
    //       title: item.title,
    //       description: item.prompt,
    //     };
    //   });

    // console.log(elements);

    // const myGallery = GLightbox({
    //   touchNavigation: true,
    //   loop: true,
    //   //   elements: elements,
    // });
    // myGallery.setElements(elements);
    // myGallery.open();

    setLightbox(
      GLightbox({
        selector: `.${type}`,
        startAt: startAt,
      })
    );

    // lightbox.open();
  };

  useEffect(() => {
    if (lightbox === null) return;
    lightbox.open();
    lightbox.on("slide_after_load", () => {
      // click .gslide-desc, copy description to clipboard
      const desc = document.querySelectorAll(".gslide-desc");
      desc.forEach((d) => {
        d.addEventListener("click", () => {
          const text = d.innerText;
          // console.log(text);
          navigator.clipboard.writeText(text);

          Swal.fire({
            title: "The prompt has been copied!",
            iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
            showConfirmButton: false,
            timer: 1500,
          });

          return;
        });
      });
    });
    lightbox.on("close", () => {
      setLightbox(null);
      lightbox.destroy();
    });
  }, [lightbox]);

  useEffect(() => {
    // const lightbox = GLightbox({
    //   selector: ".glightbox",
    // });
    // return () => lightbox.destroy();
    // Swal.fire({
    //   title: "Description copied",
    //   // text: text,
    //   iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
    //   showConfirmButton: false,
    //   //   timer: 1500,
    // });
  }, []);

  return (
    <main>
      <div className="album py-5 bg-body-tertiary">
        <div className="p-4">
          <nav className="nav nav-pills nav-fill mb-3 nav-category">
            <a
              className={
                activeCategory === "modern" ? "nav-link active" : "nav-link"
              }
              onClick={() => setActiveCategory("modern")}
            >
              Modern
            </a>
            <a
              className={
                activeCategory === "minimalist" ? "nav-link active" : "nav-link"
              }
              onClick={() => setActiveCategory("minimalist")}
            >
              Minimalist
            </a>

            <a
              className={
                activeCategory === "bauhaus" ? "nav-link active" : "nav-link"
              }
              onClick={() => setActiveCategory("bauhaus")}
            >
              Bauhaus
            </a>
            <a
              className={
                activeCategory === "north_european"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => setActiveCategory("north_european")}
            >
              North European
            </a>
            <a
              className={
                activeCategory === "vintage" ? "nav-link active" : "nav-link"
              }
              onClick={() => setActiveCategory("vintage")}
            >
              Vintage
            </a>
            <a
              className={
                activeCategory === "natural" ? "nav-link active" : "nav-link"
              }
              onClick={() => setActiveCategory("natural")}
            >
              Natural
            </a>
          </nav>

          {imageTypes
            .filter((img) => img.category === activeCategory)
            .map((imageType, index) => (
              <div key={`imagetype-${index}`} className="mb-4">
                <h4>{imageType.title}</h4>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-3">
                  {imageType.items.map((item, index2) => (
                    <div
                      className="col"
                      key={`imagetype-item-${index2}`}
                      style={{
                        display: imageType.category.includes(activeCategory)
                          ? "block"
                          : "none",
                      }}
                    >
                      <div className="card shadow-sm">
                        <img
                          src={`./assets/images${item.href}`}
                          className={`bd-placeholder-img card-img-top ${imageType.type}`}
                          width="auto"
                          height="225px"
                          // data-glightbox={`description:${item.prompt}`}
                          data-title={item.title}
                          data-description={item.prompt}
                          data-type="image"
                          data-effect="fade"
                          onClick={(e) => {
                            openGallery(imageType.type, index2);
                          }}
                        />

                        {/* <div className="card-body">
                        <p className="fw-bold">{item.title}</p>
                      </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </main>
  );
}

export default Interior;
